import Vue from 'vue'
import dayjs from 'dayjs'
import { MyFolder, DangoResponse } from '@/types/dango'
import {
  getLoginInfo,
  checkMaintenance,
  checkAccessRights,
  checkSvData,
  checkLoginData,
} from '@/plugins/user'
import { initMyFolderInfo, getMyFolderInfo } from '@/plugins/myfolder'
import { NotFoundError } from '@/plugins/exception'
import { isSpDevice } from '@/plugins/device'
const sprintf = require('sprintf-js').sprintf

// インターフェースを追加
declare global {
  interface Element {
    msMatchesSelector(selectors: string): boolean
  }
}
interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T
  key: any
}
declare module 'vue/types/vue' {
  interface Vue {
    $$getLoginInfo(key: string): number | string | boolean
    $$setMainVueInstance(): void
    $$getMainVueInstance(): any
    $$initMyFolderInfo(): void
    $$getMyFolderInfo(
      key1: keyof MyFolder.detailIF,
      key2:
        | keyof MyFolder.quoteJsonIF
        | keyof MyFolder.presearchJsonIF
        | keyof MyFolder.favoriteKijiJsonIF
        | ''
    ): any
    $$checkMaintenance(path: string): boolean
    $$checkAccessRights(path: string): boolean
    $$checkSvData(key: string): boolean
    $$showLoading(): void
    $$hideLoading(): void
    $$setLocale(locale: string): void
    $$initializeTopPage(): void
    $$initializeSearchPage(): void
    $$initializeSimplePage(printFlg: number): void
    $$validate(): Promise<string>
    $$resetKeyword(): void
    $$addWordToKeyword(word: string, target: string): void
    $$addWordToMenName(words: string[]): void
    $$getRegionEndYear(): number
    $$getDateOption(type: string): string[][]
    $$setDate(): void
    $$resetDate(): void
    $$updateTextBox(name: string): void
    $$updateListBox(name: string, i: number, gflg: number): void
    $$clearListBox(name: string): void
    $$selectAllKijiIdBox(): void
    $$clearAllKijiIdBox(): void
    $$showDetail(): void
    $$hideDetail(): void
    $$scrollToTop(e: HTMLElementEvent<HTMLElement>): void
    $$adjustScrollPosition(e: HTMLElementEvent<HTMLElement>): void
    $$switchTab(e: HTMLElementEvent<HTMLElement>): void
    $$toggleSubMenuByClick(e: HTMLElementEvent<HTMLElement>): void
    $$toggleSubMenuByKeyDown(e: HTMLElementEvent<HTMLElement>): void
    $$toggleItemList(e: HTMLElementEvent<HTMLElement>): void
    $$closeItemList(e: HTMLElementEvent<HTMLElement>): void
    $$openItemList(e: HTMLElementEvent<HTMLElement>): void
    $$toggleSearchBox(): void
    $$closeSearchBox(): void
    $$toggleTreeByClick(e: HTMLElementEvent<HTMLElement>): void
    $$toggleTreeByKeyDown(e: HTMLElementEvent<HTMLElement>): void
    $$toggleModalMenu(): void
    $$getNo(kijiId: string): number
    $$zeroPadding(num: number, length: number): string
    $$getKijiTitle(
      list: DangoResponse.searchKijiListsIF | DangoResponse.detailKijiListsIF,
      flg: number
    ): string
    $$getKijiBody(list: DangoResponse.detailKijiListsIF): string
    $$getShimenTitle(
      list: DangoResponse.searchShimenListIF,
      flg: number
    ): string
    $$getShimenKeyword(
      list: DangoResponse.searchShimenListIF,
      flg: number
    ): string
    $$getShimeBunrui(
      list: DangoResponse.detailShimenMidashiDbdetailIF,
      flg: number
    ): string
    $$getChiezoTitle(
      list:
        | DangoResponse.searchChiezoListsIF
        | DangoResponse.detailChiezoListsIF,
      flg: number
    ): string
    $$getChiezoBody(list: DangoResponse.detailChiezoListsIF): string
    $$getChiezoField(
      list:
        | DangoResponse.searchChiezoListsIF
        | DangoResponse.detailChiezoListsIF
    ): string
    $$getEnglishTitle(list: DangoResponse.searchEnglishListsIF): string
    $$getEnglishTitle2(list: DangoResponse.detailEnglishListsIF): string
    $$getEnglishLink(
      list: DangoResponse.detailEnglishListsIF
    ): [string, string][]
    $$getEnglishBody(
      list: DangoResponse.detailEnglishListsIF,
      bodyName: string
    ): string
    $$getEnglishMedia(
      list: DangoResponse.detailEnglishListsIF
    ): DangoResponse.detailEnglishMedia[]
    $$getJimbutsuInfo(
      list: DangoResponse.detailJimbutsuListsIF
    ): { key: string; name: string }[]
    $$getGraphSummary(
      summary: string,
      highlightFlg: number,
      shrinkFlg: number
    ): string
    $$getGraphKind(code: string): string
    $$getGraphVolume(code: string, volume: string): string
    $$openPdfSummary(): void
    $$closePdfSummary(): void
    $$noTouchMoveOnImage(): void
    $$zoomImage(e: HTMLElementEvent<HTMLElement>): void
    $$removeCssHover(): void
    $$getUnixTime(): number
    $$getDataFromLocalStorage(key: string): string
    $$openNewWindow(path: string, index: number, target: string): void
    $$setData(): void
    $$getKanshuNameParam(kankind: string): string
    $$gethakkoushaNameParam(shacode: string): string
    $$openStaticPage(key: string, locale: string): void
    $$setSelection(): boolean
    $$closeToolBox(): void
    $$openToolBox(e: any): Promise<void>
    $$registerToolBox(): void
    $$searchBySelectionKeyword(name: string): void
    $$addQuote(): void
    $$formatQuote(
      updatetime: string,
      text: string,
      list: any,
      title: string
    ): void
    $$copyQuote(): void
    $$localizePresearch(srchInfo: any): any
    $$addPresearch(): void
    $$addFavoriteKiji(): void
    $$updateFavoriteKijiMemo(
      uuid: string,
      kijiId: string,
      i: number
    ): Promise<void>
    $$getDate(datetime: string, format: string): string
    $$getInitialValue(key: string): any
    $$getLocaleMessage(key: string): string
    $$setLoginPagePath(): void
    $$unifyChar(str: string): string
  }
}

/**
 * @type {number} 画像の拡大・縮小サイズ
 */
const imageZoomSize: number = 100

/**
 * 正規表現のメタ文字をエスケープ
 * @param {string} str エスケープさせたい文字列
 * @return {string} エスケープされた文字列を返す
 */
const regExpEscape = (str: string): string => {
  return str.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
}

/**
 * ユーザー情報を取得する
 * @params {keyof DangoResponse.loginInfo} key
 * @return {number|string|boolean}
 */
Vue.prototype.$$getLoginInfo = function (
  key: keyof DangoResponse.loginInfo
): number | string | boolean {
  return getLoginInfo(key)
}

// mainコンポーネントのビューインスタンス
let mainVueInstance = {}

/**
 * mainコンポーネントのVueインスタンスをセットする
 * @return {void}
 */
Vue.prototype.$$setMainVueInstance = function (): void {
  mainVueInstance = this
}

/**
 * mainコンポーネントのVueインスタンスを取得する
 * @return {any}
 */
Vue.prototype.$$getMainVueInstance = function (): any {
  return mainVueInstance
}

/**
 * マイフォルダ情報を初期化する
 * @return {void}
 */
Vue.prototype.$$initMyFolderInfo = function (): void {
  initMyFolderInfo()
}

/**
 * マイフォルダ情報を取得する
 * @params {keyof MyFolder.detailIF} key1
 * @params {keyof MyFolder.quoteJsonIF | keyof MyFolder.presearchJsonIF | keyof MyFolder.favoriteKijiJsonIF | ''} key2
 * @return {any}
 */
Vue.prototype.$$getMyFolderInfo = function (
  key1: keyof MyFolder.detailIF,
  key2:
    | keyof MyFolder.quoteJsonIF
    | keyof MyFolder.presearchJsonIF
    | keyof MyFolder.favoriteKijiJsonIF
    | '' = ''
): any {
  return getMyFolderInfo(key1, key2)
}

/**
 * メンテナンス中か確認する
 * @params {string} path
 * @return {boolean}
 */
Vue.prototype.$$checkMaintenance = function (path: string): boolean {
  return checkMaintenance(path)
}

/**
 * ページのアクセス権を確認する
 * @params {string} path
 * @return {boolean}
 */
Vue.prototype.$$checkAccessRights = function (path: string): boolean {
  return checkAccessRights(path)
}

/**
 * サービスの権限を確認する
 * @params {string} key
 * @return {boolean}
 */
Vue.prototype.$$checkSvData = function (key: string): boolean {
  return checkSvData(key)
}

/**
 * ローディングを表示する
 * @return {void}
 */
Vue.prototype.$$showLoading = function (): void {
  document.body.classList.add('loading')
  const el = document.getElementById('loading')
  if (el) {
    el.style.display = ''
  }
}

/**
 * ローディングを隠す
 * @return {void}
 */
Vue.prototype.$$hideLoading = function (): void {
  const el = document.getElementById('loading')
  if (el) {
    el.style.display = 'none'
  }
  document.body.classList.remove('loading')
}

/**
 * localeをセットする
 * @params {string} locale
 * @return {void}
 */
Vue.prototype.$$setLocale = function (locale: string): void {
  if (locale === 'en') {
    this.$i18n.setLocale('en')
    document.body.classList.add('english')
  } else {
    this.$i18n.setLocale('ja')
    document.body.classList.remove('english')
  }
}

/**
 * TOPページ初期化処理
 * @return {void}
 */
Vue.prototype.$$initializeTopPage = function (): void {
  // mainコンポーネントのVueインスタンスをセットする
  this.$$setMainVueInstance()

  // localeをセットする
  this.$$setLocale(this.$i18n.locale)

  // エラーメッセージは日本語
  this.$$setValidationLocale('ja')

  // bodyにtopクラスを追加
  document.body.classList.add('top')

  // ローディングを非表示にする
  this.$$hideLoading()
}

/**
 * 検索ページ初期化処理
 * @return {void}
 */
Vue.prototype.$$initializeSearchPage = function (): void {
  // mainコンポーネントのVueインスタンスをセットする
  this.$$setMainVueInstance()

  // localeをセットする
  this.$$setLocale(this.$i18n.locale)

  // エラーメッセージは日本語
  this.$$setValidationLocale('ja')

  // bodyにtopクラスを削除
  document.body.classList.remove('top')

  // リサイザーを登録する
  registerResizer()

  window.addEventListener('resize', function () {
    fitResizer()
  })

  // ローディングを非表示にする
  this.$$hideLoading()
}

/**
 * simpleクラス使用ページ初期化処理
 * @params {number} printFlg
 * @return {void}
 */
Vue.prototype.$$initializeSimplePage = function (printFlg: number): void {
  // mainコンポーネントのVueインスタンスをセットする
  this.$$setMainVueInstance()

  // localeをセットする
  this.$$setLocale(this.$i18n.locale)

  // エラーメッセージは日本語
  this.$$setValidationLocale('ja')

  // bodyにtopクラスを追加
  document.body.classList.add('simple')
  document.body.classList.add('breadcrumb')
  if (printFlg === 1) {
    document.body.classList.add('print')
  } else if (printFlg === 2) {
    document.body.classList.add('dispCropResult')
  } else if (printFlg === 3) {
    document.body.classList.add('simple-pdf')
  }
}

/**
 * バリデーションエラーがあればエラーメッセージを返す
 * @return {Promise<string>}
 */
Vue.prototype.$$validate = async function (): Promise<string> {
  const isValid = await (this.$refs.form as any).validate()
  let errorMessage: string = ''
  if (!isValid) {
    const errors = (this.$refs.form as any).errors
    Object.keys(errors).forEach((key: string) => {
      if (errors[key].length >= 1) {
        if (errorMessage !== '') {
          errorMessage += '\n'
        }
        errorMessage += errors[key].join('\n')
      }
    })
  }
  return errorMessage
}

/**
 * キーワードをリセット
 * @return {void}
 */
Vue.prototype.$$resetKeyword = function (): void {
  this.srchInfo[0].keyword = ''
  const keyword: HTMLInputElement = this.$refs.keyword as HTMLInputElement
  keyword.focus()
}

/**
 * キーワードに単語を追加
 * @params {string} word
 * @params {string} target
 * @return {void}
 */
Vue.prototype.$$addWordToKeyword = function (
  word: string,
  target: string = 'keyword'
): void {
  if (
    word !== this.$$const.FORM.operator.AND &&
    word !== this.$$const.FORM.operator.OR &&
    word !== this.$$const.FORM.operator.NOT
  ) {
    if (
      this.srchInfo[0][target] === '' ||
      this.srchInfo[0][target].match(/\($/)
    ) {
      word = word.trim()
    }
  }
  this.srchInfo[0][target] += word
  const el: HTMLInputElement = this.$refs[target] as HTMLInputElement
  el.focus()
}

/**
 * 面名に単語を追加
 * @params {string[]} words
 * @return {void}
 */
Vue.prototype.$$addWordToMenName = function (words: string[]): void {
  for (const word of words) {
    if (this.srchInfo[0].menName !== '') {
      this.srchInfo[0].menName += ' ' + this.$$const.FORM.operator.OR + ' '
    }
    this.srchInfo[0].menName += word
  }
}

/**
 * 年から和暦を取得する
 * @params {number} year
 * @params {number} type
 * @return {string} wareki
 */
Vue.prototype.$$getWarekiFromYear = function (
  year: number,
  type: number = 1
): string {
  let wareki = ''
  const eraInfo: (string | number)[][] = [
    ['明', '明治', 1868, 1912],
    ['大', '大正', 1912, 1926],
    ['昭', '昭和', 1926, 1989],
    ['平', '平成', 1989, 2019],
  ]
  let era: string = ''
  for (const info of eraInfo) {
    if (type) {
      era = String(info[0])
    } else {
      era = String(info[1])
    }
    const startYear = Number(info[2])
    const endYear = Number(info[3])
    if (startYear <= year && year <= endYear) {
      if (year === startYear) {
        if (wareki !== '') {
          wareki += '/'
        }
        wareki += era + '元'
      } else {
        const y: number = year - startYear + 1
        wareki += era + y
      }
    }
  }
  return wareki
}

/**
 * 地域面の日付プルダウンの最終年を取得
 * @return {number}
 */
Vue.prototype.$$getRegionEndYear = function (): number {
  let year = 1999
  if (this.$$getLoginInfo('ASA_SEARCH_FLAG') === 'Y') {
    year = 2005
  }
  console.log('$$getRegionEndYear', year)
  return year
}

/**
 * 日付プルダウンのオプションを取得
 * @params {string} type year/month/day/conj
 * @return {string[][]}
 */
Vue.prototype.$$getDateOption = function (type: string): string[][] {
  const options: string[][] = []
  if (type === 'year_kiji') {
    options.push(['', ''])
    let c = 1984
    if (this.$$getLoginInfo('SV_PERIOD') !== 0) {
      c = dayjs().year() - this.$$getLoginInfo('SV_PERIOD')
    }
    for (let y = dayjs().year(); y >= c; y--) {
      options.push([String(y), String(y)])
    }
  } else if (type === 'year_honshi_kw' || type === 'year_honshi_date') {
    options.push(['', ''])

    const target: number[] = []

    // 期間指定許可
    if (
      this.$$getLoginInfo('SV_DATA_SHIMEN') === 'Y' &&
      this.$$getLoginInfo('L_OPT_SHIMEN') === 'Y'
    ) {
      // 期間１
      if (
        this.$$getLoginInfo('L_OPT_SHIMEN_E1') !== '' &&
        this.$$getLoginInfo('L_OPT_SHIMEN_S1') !== ''
      ) {
        for (
          let y = this.$$getLoginInfo('L_OPT_SHIMEN_E1');
          y >= this.$$getLoginInfo('L_OPT_SHIMEN_S1');
          y--
        ) {
          target.push(Number(y))
        }
      }

      // 期間２
      if (
        this.$$getLoginInfo('L_OPT_SHIMEN_E2') !== '' &&
        this.$$getLoginInfo('L_OPT_SHIMEN_S2') !== ''
      ) {
        for (
          let y = this.$$getLoginInfo('L_OPT_SHIMEN_E2');
          y >= this.$$getLoginInfo('L_OPT_SHIMEN_S2');
          y--
        ) {
          target.push(Number(y))
        }
      }
    } else {
      // 平成
      if (this.$$checkSrchAgesAuth('HEISEI')) {
        let heiseiEnd: number = this.$$const.FORM.srchAgePeriod.HEISEI.END
        if (type === 'year_honshi_date') {
          heiseiEnd = this.$$getLoginInfo('SV_SHIMEN_HEISEI_END')
        }
        for (
          let y = heiseiEnd;
          y >= this.$$const.FORM.srchAgePeriod.HEISEI.START;
          y--
        ) {
          target.push(Number(y))
        }
      }

      // 昭和（戦後）
      if (this.$$checkSrchAgesAuth('SENGO')) {
        for (
          let y = this.$$const.FORM.srchAgePeriod.SENGO.END;
          y >= this.$$const.FORM.srchAgePeriod.SENGO.START;
          y--
        ) {
          target.push(Number(y))
        }
      }

      // 昭和（戦前）
      if (this.$$checkSrchAgesAuth('SENZEN')) {
        for (
          let y = this.$$const.FORM.srchAgePeriod.SENZEN.END;
          y >= this.$$const.FORM.srchAgePeriod.SENZEN.START;
          y--
        ) {
          target.push(Number(y))
        }
      }

      // 明治・大正
      if (this.$$checkSrchAgesAuth('MEIJI')) {
        for (
          let y = this.$$const.FORM.srchAgePeriod.MEIJI.END;
          y >= this.$$const.FORM.srchAgePeriod.MEIJI.START;
          y--
        ) {
          target.push(Number(y))
        }
      }
    }

    // 重複削除・降順ソート
    const sortedTarget: number[] = target
      .filter(function (x, i, self) {
        return self.indexOf(x) === i
      })
      .sort(function (a, b) {
        if (a > b) return -1
        if (a < b) return 1
        return 0
      })

    // 返却用配列に格納
    for (const y of sortedTarget) {
      options.push([
        String(y),
        String(y) + '(' + this.$$getWarekiFromYear(y) + ')',
      ])
    }
  } else if (type === 'year_chiiki') {
    options.push(['', ''])
    for (let y = this.$$getRegionEndYear(); y >= 1900; y--) {
      options.push([
        String(y),
        String(y) + '(' + this.$$getWarekiFromYear(y) + ')',
      ])
    }
  } else if (type === 'year_ocr') {
    options.push(['', ''])
    for (let y = 1996; y >= 1991; y--) {
      options.push([
        String(y),
        String(y) + '(' + this.$$getWarekiFromYear(y) + ')',
      ])
    }
  } else if (type === 'year_gaichi') {
    options.push(['', ''])
    for (let y = 1945; y >= 1935; y--) {
      options.push([
        String(y),
        String(y) + '(' + this.$$getWarekiFromYear(y) + ')',
      ])
    }
  } else if (type === 'year_graph') {
    options.push(['', ''])
    let end = 1956
    if (this.$$getLoginInfo('ASAHIGRAPH_END_NEN')) {
      end = this.$$getLoginInfo('ASAHIGRAPH_END_NEN')
    }
    for (let y = end; y >= 1923; y--) {
      options.push([
        String(y),
        String(y) + '(' + this.$$getWarekiFromYear(y) + ')',
      ])
    }
  } else if (type === 'month') {
    options.push(['', ''])
    for (let m = 1; m <= 12; m++) {
      options.push([String(m), String(m)])
    }
  } else if (type === 'day') {
    options.push(['', ''])
    options.push([
      this.$$const.FORM.daySeason.EARLY,
      this.$t('daySeasonParamLabel.EARLY'),
    ])
    options.push([
      this.$$const.FORM.daySeason.MID,
      this.$t('daySeasonParamLabel.MID'),
    ])
    options.push([
      this.$$const.FORM.daySeason.LATE,
      this.$t('daySeasonParamLabel.LATE'),
    ])
    for (let d = 1; d <= 31; d++) {
      options.push([String(d), String(d)])
    }
  } else if (type === 'conj') {
    options.push([
      this.$$const.FORM.hakkouDateConjunction.FROM,
      this.$t('hakkouDateConjunctionParamLabel.FROM'),
    ])
    options.push([
      this.$$const.FORM.hakkouDateConjunction.ONLY,
      this.$t('hakkouDateConjunctionParamLabel.ONLY'),
    ])
    options.push([
      this.$$const.FORM.hakkouDateConjunction.UNTIL,
      this.$t('hakkouDateConjunctionParamLabel.UNTIL'),
    ])
  }
  return options
}

/**
 * 日付をセット
 * @return {void}
 */
Vue.prototype.$$setDate = function (): void {
  const s = this.srchInfo[0]
  if (s.srchTerm !== undefined) {
    s.srchTerm = this.$$const.API.srchTerm.FROM_TO
  }
  s.hakkouDateF = ''
  s.hakkouDateT = ''
  const date = [
    { key: 'hakkouDateF', y: '', m: '', d: '' },
    { key: 'hakkouDateT', y: '', m: '', d: '' },
  ]
  if (
    s.hakkouDateConjunction === this.$$const.FORM.hakkouDateConjunction.FROM
  ) {
    date[0].y = s.hakkouDateF_y
    date[0].m = s.hakkouDateF_m
    date[0].d = s.hakkouDateF_d
    date[1].y = s.hakkouDateT_y
    date[1].m = s.hakkouDateT_m
    date[1].d = s.hakkouDateT_d
  } else if (
    s.hakkouDateConjunction === this.$$const.FORM.hakkouDateConjunction.ONLY
  ) {
    date[0].y = s.hakkouDateF_y
    date[0].m = s.hakkouDateF_m
    date[0].d = s.hakkouDateF_d
    date[1].y = date[0].y
    date[1].m = date[0].m
    date[1].d = date[0].d
  } else if (
    s.hakkouDateConjunction === this.$$const.FORM.hakkouDateConjunction.UNTIL
  ) {
    date[1].y = s.hakkouDateF_y
    date[1].m = s.hakkouDateF_m
    date[1].d = s.hakkouDateF_d
  }

  for (const obj of date) {
    const key = obj.key
    const y = obj.y
    const m = obj.m
    let d = obj.d
    console.log(key + ':y:' + y)
    console.log(key + ':m:' + m)
    console.log(key + ':d:' + d)
    // 年、月、日の指定がなければ次へ
    if (y === '' && m === '' && d === '') {
      continue
    }

    // 年が空で月、日の指定をする事はできない
    if (y === '') {
      s[key] = 'Invalid date'
    } else if (m !== '') {
      // 「年：空でない、月：空でない、日：空でない、期間：FROM」
      if (d !== '' && key === 'hakkouDateF') {
        if (d === this.$$const.FORM.daySeason.EARLY) {
          d = '1'
        } else if (d === this.$$const.FORM.daySeason.MID) {
          d = '10'
        } else if (d === this.$$const.FORM.daySeason.LATE) {
          d = '20'
        }
        // 「年：空でない、月：空でない、日：空でない、期間：TO」
      } else if (d !== '' && key === 'hakkouDateT') {
        if (d === this.$$const.FORM.daySeason.EARLY) {
          d = '10'
        } else if (d === this.$$const.FORM.daySeason.MID) {
          d = '20'
        } else if (d === this.$$const.FORM.daySeason.LATE) {
          d = String(dayjs(y + '-' + m, 'YYYY-MM').daysInMonth())
        }
        // 「年：空でない、月：空でない、日：空、期間：FROM」
      } else if (d === '' && key === 'hakkouDateF') {
        d = '1'
        // 「年：空でない、月：空でない、日：空、期間：TO」
      } else if (d === '' && key === 'hakkouDateT') {
        d = String(dayjs(y + '-' + m, 'YYYY-MM').daysInMonth())
      }
      const date = dayjs(y + '-' + m + '-' + d, 'YYYY-MM-DD').format('YYYYMMDD')
      if (date === sprintf('%04d%02d%02d', y, m, d)) {
        s[key] = date
      } else {
        s[key] = 'Invalid date'
      }
    } else if (m === '') {
      // 「年：空でない、月：空、日：空でない」は指定不可
      if (d !== '') {
        s[key] = 'Invalid date'

        // 「年：空でない、月：空、日：空、期間：FROM」
      } else if (d === '' && key === 'hakkouDateF') {
        s[key] = y + '0101'

        // 「年：空でない、月：空、日：空、期間：FROM」
      } else if (d === '' && key === 'hakkouDateT') {
        s[key] = y + '1231'
      }
    }
  }
  if (s.hakkouDateF === 'Invalid date' && s.hakkouDateT === 'Invalid date') {
    s.hakkouDateT = ''
  }

  // 地域面・外地版の場合、空のFROM/TOを埋める
  if (s.srchId === 'S' && s.menshuAName !== 'H') {
    let fromY = ''
    let toY = ''
    if (s.menshuAName === 'L') {
      fromY = '1900'
      toY = '1999'
    } else {
      fromY = '1935'
      toY = '1945'
    }
    if (s.hakkouDateF === '' && s.hakkouDateT === '') {
      s.hakkouDateF = ''
      s.hakkouDateT = ''
    } else {
      if (s.hakkouDateF === '') {
        s.hakkouDateF = fromY + '0101'
      }
      if (s.hakkouDateT === '') {
        s.hakkouDateT = toY + '1231'
      }
    }
  }
  console.log('hakkouDateF:' + s.hakkouDateF)
  console.log('hakkouDateT:' + s.hakkouDateT)
}

/**
 * 日付をリセット
 * @return {void}
 */
Vue.prototype.$$resetDate = function (): void {
  const s = this.srchInfo[0]
  if (s.srchTerm !== 'FT') {
    s.hakkouDateF = ''
    s.hakkouDateT = ''
    s.hakkouDateF_y = ''
    s.hakkouDateF_m = ''
    s.hakkouDateF_d = ''
    s.hakkouDateConjunction = this.$$const.FORM.hakkouDateConjunction.FROM
    s.hakkouDateT_y = ''
    s.hakkouDateT_m = ''
    s.hakkouDateT_d = ''
  }
}

/**
 * テキストボックスを更新する
 * @params {string} name
 * @return {void}
 */
Vue.prototype.$$updateTextBox = function (name: string): void {
  this.srchInfo[0][name] = this.listBox[name].join(' OR ')
}

/**
 * リストボックス内のチェックボックスを更新する
 * @params {string} name
 * @params {number} i
 * @params {number} gflg
 * @return {void}
 */
Vue.prototype.$$updateListBox = function (
  name: string,
  i: number,
  gflg: number
): void {
  if (gflg) {
    let checked = false
    if (
      this.listBox[name + 'Group'].find((n: number) => n === i) !== undefined
    ) {
      checked = true
    }
    for (const el of this.$el.querySelectorAll(
      '[id*="listBox.' + name + i + '_"]'
    )) {
      if (checked) {
        if (
          this.listBox[name].find((v: string) => v === el.value) === undefined
        ) {
          this.listBox[name].push(el.value)
        }
      } else {
        this.listBox[name] = this.listBox[name].filter(
          (v: string) => v !== el.value
        )
      }
    }
  }

  this.srchInfo[0][name] = this.listBox[name].join(' OR ')

  const arr = []
  for (const el of this.$el.querySelectorAll(
    '[id*="listBox.' + name + 'Group"]'
  )) {
    const i = el.value
    let flg = true
    for (const el2 of this.$el.querySelectorAll(
      '[id*="listBox.' + name + i + '_"]'
    )) {
      if (
        this.listBox[name].find((v: string) => v === el2.value) === undefined
      ) {
        flg = false
      }
    }
    if (flg === true) {
      arr.push(i)
    }
  }
  this.listBox[name + 'Group'] = arr
}

// polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s: any) {
    let el = this
    do {
      if (el.matches(s)) return el
      el = (el.parentElement || el.parentNode) as Element
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

/**
 * リストボックスの選択を全解除する
 * @params {string} name
 * @return {void}
 */
Vue.prototype.$$clearListBox = function (name: string): void {
  this.listBox[name] = []
  this.$$updateListBox(name)
}

/**
 * 記事IDのチェックボックスを全て選択する
 * @return {void}
 */
Vue.prototype.$$selectAllKijiIdBox = function (): void {
  const kijiIdBox = []
  for (const el of this.$el.querySelectorAll('.md-topic-list__checkbox')) {
    kijiIdBox.push(el.value.split(','))
  }
  this.kijiIdBox = kijiIdBox
}

/**
 * 記事IDのチェックボックスを全てクリアする
 * @return {void}
 */
Vue.prototype.$$clearAllKijiIdBox = function (): void {
  this.kijiIdBox = []
}

// リサイザー制御用変数
let targetId = ''
let targetBox: any = null

/**
 * mousemove時のコールバック関数
 * @params {any} e
 * @return {void}
 */
const resizerMove = (e: any): void => {
  if (!e.target) {
    return
  }
  if (!targetBox) {
    return
  }
  const mainBox = document.getElementById('gridMainBox')
  if (!mainBox) {
    return
  }
  const mainBoxStyle = window.getComputedStyle(mainBox)
  if (!mainBoxStyle) {
    return
  }
  const mainBoxLeft = document.getElementById('gridMainBox-left')
  if (!mainBoxLeft) {
    return
  }
  const mainBoxCenter = document.getElementById('gridMainBox-center')
  if (!mainBoxCenter) {
    return
  }
  const mainBoxRight = document.getElementById('gridMainBox-right')
  if (!mainBoxRight) {
    return
  }

  const mainBoxLeftW = mainBoxLeft.offsetWidth
  const mainBoxCenterW = mainBoxCenter.offsetWidth
  const mainBoxRightW = mainBoxRight.offsetWidth

  if (!document.body.classList.contains('is-resize-move')) {
    document.body.classList.add('is-resize-move')
  }

  const grid = mainBoxStyle.getPropertyValue('grid-template-columns').split(' ')
  const mGrid = mainBoxStyle.getPropertyValue('-ms-grid-columns').split(' ')
  const g = { left: grid[0], center: grid[1], right: grid[2] }
  const mg = { left: mGrid[0], center: mGrid[1], right: mGrid[2] }

  if (targetId === 'mdResizer-left') {
    if (mainBoxLeftW > 47 && mainBoxCenterW > 47) {
      const obj = document.getElementById('gridMainBox-left')
      if (!obj) {
        return
      }
      const rect = obj.getBoundingClientRect()
      const resizeX = e.clientX - rect.left
      g.left = resizeX + 'px'
      mg.left = resizeX + 'px'
      targetBox.style.left = resizeX - 3 + 'px'
      mainBox.style.setProperty(
        'grid-template-columns',
        g.left + ' 1fr ' + g.right
      )
      mainBox.style.setProperty(
        '-ms-grid-columns',
        mg.left + ' 1fr ' + mg.right
      )
    }
  } else if (targetId === 'mdResizer-right') {
    if (mainBoxCenterW > 47 && mainBoxRightW > 47) {
      const obj = document.getElementById('gridMainBox-right')
      if (!obj) {
        return
      }
      const rect = obj.getBoundingClientRect()
      const resizeX = rect.left - e.clientX + rect.width
      g.right = resizeX + 'px'
      mg.right = resizeX + 'px'
      targetBox.style.right = resizeX - 3 + 'px'
      mainBox.style.setProperty(
        'grid-template-columns',
        g.left + ' 1fr ' + g.right
      )
      mainBox.style.setProperty(
        '-ms-grid-columns',
        mg.left + ' 1fr ' + mg.right
      )
    }
  }
}

/**
 * mouseup時のコールバック関数
 * @return {void}
 */
const resizerStop = (): void => {
  const mainBox = document.getElementById('gridMainBox')
  if (!mainBox) {
    return
  }
  const mainBoxStyle = window.getComputedStyle(mainBox)
  if (!mainBoxStyle) {
    return
  }
  const mainBoxLeft = document.getElementById('gridMainBox-left')
  if (!mainBoxLeft) {
    return
  }
  const mainBoxCenter = document.getElementById('gridMainBox-center')
  if (!mainBoxCenter) {
    return
  }
  const mainBoxRight = document.getElementById('gridMainBox-right')
  if (!mainBoxRight) {
    return
  }
  const resizerLeft = document.getElementById('mdResizer-left')
  if (!resizerLeft) {
    return
  }
  const resizerRight = document.getElementById('mdResizer-right')
  if (!resizerRight) {
    return
  }

  const mainBoxW = mainBox.offsetWidth
  const mainBoxLeftW = mainBoxLeft.offsetWidth
  const mainBoxCenterW = mainBoxCenter.offsetWidth
  const mainBoxRightW = mainBoxRight.offsetWidth

  if (mainBoxCenterW <= 50) {
    const gCenter = 50
    const mgCenter = 50
    if (targetId === 'mdResizer-left') {
      const gLeft = mainBoxW - mainBoxRightW - gCenter
      const mgLeft = mainBoxW - mainBoxRightW - mgCenter
      resizerLeft.style.left = gLeft - 3 + 'px'
      mainBox.style.setProperty(
        'grid-template-columns',
        gLeft + 'px ' + gCenter + 'px ' + mainBoxRightW + 'px'
      )
      mainBox.style.setProperty(
        '-ms-grid-columns',
        mgLeft + 'px ' + mgCenter + 'px ' + mainBoxRightW + 'px'
      )
    } else {
      const gRight = mainBoxW - mainBoxLeftW - gCenter
      const mgRight = mainBoxW - mainBoxLeftW - mgCenter
      resizerRight.style.right = gRight - 3 + 'px'
      mainBox.style.setProperty(
        'grid-template-columns',
        mainBoxLeftW + 'px ' + gCenter + 'px ' + gRight + 'px'
      )
      mainBox.style.setProperty(
        '-ms-grid-columns',
        mainBoxLeftW + 'px ' + mgCenter + 'px ' + mgRight + 'px'
      )
    }
  } else if (targetId === 'mdResizer-left' && mainBoxLeftW <= 50) {
    const gLeft = 50
    const mgLeft = 50
    const gCenter = mainBoxW - gLeft - mainBoxRightW
    const mgCenter = mainBoxW - mgLeft - mainBoxRightW
    resizerLeft.style.left = gLeft - 3 + 'px'
    mainBox.style.setProperty(
      'grid-template-columns',
      gLeft + 'px ' + gCenter + 'px ' + mainBoxRightW + 'px'
    )
    mainBox.style.setProperty(
      '-ms-grid-columns',
      mgLeft + 'px ' + mgCenter + 'px ' + mainBoxRightW + 'px'
    )
  } else if (targetId === 'mdResizer-right' && mainBoxRightW <= 50) {
    const gRight = 50
    const mgRight = 50
    const gCenter = mainBoxW - gRight - mainBoxLeftW
    const mgCenter = mainBoxW - mgRight - mainBoxLeftW
    resizerRight.style.right = gRight + 3 + 'px'
    mainBox.style.setProperty(
      'grid-template-columns',
      mainBoxLeftW + 'px ' + gCenter + 'px ' + gRight + 'px'
    )
    mainBox.style.setProperty(
      '-ms-grid-columns',
      mainBoxLeftW + 'px ' + mgCenter + 'px ' + mgRight + 'px'
    )
  }

  document.body.classList.remove('is-resize-move')
  mainBox.removeEventListener('mousemove', resizerMove, false)
  mainBox.removeEventListener('mouseup', resizerStop, false)
}

/**
 * リサイザーを登録する
 * @return {void}
 */
const registerResizer = (): void => {
  const resizers = document.querySelectorAll('.md-resizer')
  for (let i = 0; i < resizers.length; i++) {
    resizers[i].addEventListener('mousedown', (e: any) => {
      targetId = e.target.id
      targetBox = document.getElementById(targetId)
      const mainBox = document.getElementById('gridMainBox')
      if (!mainBox) {
        return
      }
      mainBox.addEventListener('mousemove', resizerMove, false)
      mainBox.addEventListener('mouseup', resizerStop, false)
    })
  }
}

/**
 * リサイザーを合わせる
 * @return {void}
 */
const fitResizer = (): void => {
  const mainBox = document.getElementById('gridMainBox')
  if (!mainBox) {
    return
  }
  const mainBoxLeft = document.getElementById('gridMainBox-left')
  if (!mainBoxLeft) {
    return
  }
  const mainBoxCenter = document.getElementById('gridMainBox-center')
  if (!mainBoxCenter) {
    return
  }
  const resizerRight = document.getElementById('mdResizer-right')
  if (!resizerRight) {
    return
  }
  resizerRight.style.right =
    mainBox.offsetWidth -
    mainBoxLeft.offsetWidth -
    mainBoxCenter.offsetWidth -
    3 +
    'px'
}

/**
 * 記事詳細プレビューを表示する
 * @return {void}
 */
Vue.prototype.$$showDetail = function (): void {
  const mainBox = document.getElementById('gridMainBox')
  if (!mainBox) {
    return
  }
  mainBox.classList.add('is-view-right')
  const mainBoxRight = document.getElementById('gridMainBox-right')
  if (!mainBoxRight) {
    return
  }
  const resizerRight = document.getElementById('mdResizer-right')
  if (!resizerRight) {
    return
  }
  document.body.classList.add('fixed')
  mainBoxRight.setAttribute('data-selected', 'true')

  // リサイザー調整
  const mainBoxStyle = window.getComputedStyle(mainBox)
  const grid = mainBoxStyle.getPropertyValue('grid-template-columns').split(' ')
  const mGrid = mainBoxStyle.getPropertyValue('-ms-grid-columns').split(' ')
  const g = { left: grid[0], center: grid[1], right: grid[2] }
  const mg = { left: mGrid[0], center: mGrid[1], right: mGrid[2] }

  if (grid.length > 0) {
    mainBox.style.setProperty(
      'grid-template-columns',
      g.left + ' 1fr ' + '370px'
    )
    mainBox.style.setProperty('-ms-grid-columns', mg.left + ' 1fr ' + '370px')
    resizerRight.style.right = '367px'
  }
}

/**
 * 記事詳細プレビューを非表示にする
 * @return {void}
 */
Vue.prototype.$$hideDetail = function (): void {
  const mainBox = document.getElementById('gridMainBox')
  if (!mainBox) {
    return
  }
  mainBox.classList.remove('is-view-right')
  const mainBoxRight = document.getElementById('gridMainBox-right')
  if (!mainBoxRight) {
    return
  }
  const resizerRight = document.getElementById('mdResizer-right')
  if (!resizerRight) {
    return
  }
  document.body.classList.remove('fixed')
  window.scrollTo(0, -parseInt(document.body.style.top))
  mainBoxRight.setAttribute('data-selected', 'false')

  // リサイザー調整
  const mainBoxStyle = window.getComputedStyle(mainBox)
  const grid = mainBoxStyle.getPropertyValue('grid-template-columns').split(' ')
  const mGrid = mainBoxStyle.getPropertyValue('-ms-grid-columns').split(' ')
  const g = { left: grid[0], center: grid[1], right: grid[2] }
  const mg = { left: mGrid[0], center: mGrid[1], right: mGrid[2] }

  if (grid.length > 0) {
    mainBox.style.setProperty('grid-template-columns', g.left + ' 1fr ' + '0')
    mainBox.style.setProperty('-ms-grid-columns', mg.left + ' 1fr ' + '0')
    resizerRight.style.right = '327px'
  }
}

/**
 * ページ上部にスクロールする
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$scrollToTop = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const t = e.target
  const scrlTarget = t.closest('.l-main-box__item')
  if (!scrlTarget) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  } else if (window.innerWidth <= parent.$nuxt.$$const.DEVICE.MOBILE_WIDTH) {
    const el = t.closest('.md-article-box__inner')
    if (scrlTarget.id === 'gridMainBox-right' && el) {
      el.scrollTop = 0
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  } else {
    scrlTarget.scrollTop = 0
  }
}

/**
 * スクロール位置を調整する
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$adjustScrollPosition = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  console.log('adjustScrollPosition')
  e.preventDefault()
  const t = e.target
  const id = t.getAttribute('link')
  if (!id) {
    return
  }
  const el = document.getElementById(id)
  if (!el) {
    return
  }
  let scrlTarget = el.closest('.l-main-box__item')
  let scrlTargetYoffset = 0
  if (!scrlTarget) {
    scrlTarget = document.getElementById('app')
    scrlTargetYoffset = window.pageYOffset
  } else if (window.innerWidth <= parent.$nuxt.$$const.DEVICE.MOBILE_WIDTH) {
    scrlTargetYoffset = window.pageYOffset
  } else {
    scrlTargetYoffset = scrlTarget.scrollTop
  }
  if (!scrlTarget) {
    return
  }
  const targetY = el.getBoundingClientRect().top + scrlTargetYoffset - 100
  if (window.innerWidth <= parent.$nuxt.$$const.DEVICE.MOBILE_WIDTH) {
    if (scrlTarget.id === 'gridMainBox-right') {
      const el2 = el.closest('.md-article-box__inner')
      if (!el2) {
        return
      }
      el2.scrollTop = 0
    } else {
      window.scrollTo(0, targetY)
    }
  } else {
    scrlTarget.scrollTop = targetY
  }
}

/**
 * タブを切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$switchTab = function (e: HTMLElementEvent<HTMLElement>): void {
  const t = e.target
  const targetId = t.getAttribute('id')
  const p = t.parentNode as HTMLElement
  if (!p) {
    return
  }
  const pp = p.parentNode as HTMLElement
  if (!pp) {
    return
  }
  const ppn = pp.nextElementSibling
  if (!ppn) {
    return
  }
  const li = p.children
  const panels = ppn.children
  for (let i = 0; i < li.length; i++) {
    const id = li[i].getAttribute('id')
    li[i].setAttribute('aria-selected', id === targetId ? 'true' : 'false')
    panels[i].setAttribute('aria-hidden', id === targetId ? 'false' : 'true')
  }
}

/**
 * クリックしてサブメニュー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$toggleSubMenuByClick = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  e.target.setAttribute(
    'aria-pressed',
    e.target.getAttribute('aria-pressed') === 'true' ? 'false' : 'true'
  )
}

/**
 * キーダウンしてサブメニュー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$toggleSubMenuByKeyDown = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  // Space や Enter、Spacebar（IE11）が押されたかどうかを確認
  if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
    // デフォルトのアクションを防止して Space が押されたときにスクロールするのを止める
    e.preventDefault()
    e.target.setAttribute(
      'aria-pressed',
      e.target.getAttribute('aria-pressed') === 'true' ? 'false' : 'true'
    )
  }
}

/**
 * アイテム一覧表示を切り替える
 * @params {HTMLElementEvent<Element>} e
 * @return {void}
 */
Vue.prototype.$$toggleItemList = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const btn = e.target
  const id = btn.getAttribute('aria-controls')
  if (!id) {
    return
  }
  const t = document.getElementById(id)
  if (!t) {
    return
  }
  btn.setAttribute(
    'aria-selected',
    btn.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
  t.setAttribute(
    'aria-hidden',
    t.getAttribute('aria-hidden') === 'true' ? 'false' : 'true'
  )
}

/**
 * アイテム一覧表示を表示する
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$openItemList = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const btn = e.target
  const id = btn.getAttribute('aria-controls')
  if (!id) {
    return
  }
  const t = document.getElementById(id)
  if (!t) {
    return
  }
  btn.setAttribute('aria-selected', 'true')
  t.setAttribute('aria-hidden', 'false')
}

/**
 * アイテム一覧表示を非表示にする
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$closeItemList = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  const t = e.target.closest('[role="tabpanel"]')
  if (!t) {
    return
  }
  const id = t.getAttribute('aria-labelledby')
  if (!id) {
    return
  }
  const btn = document.getElementById(id)
  if (!btn) {
    return
  }
  btn.setAttribute('aria-selected', 'false')
  t.setAttribute('aria-hidden', 'true')
}

/**
 * 検索ボックスの表示を切り替える
 * @return {void}
 */
Vue.prototype.$$toggleSearchBox = function (): void {
  const btn = document.getElementById('SearchBoxNaviBtn')
  if (!btn) {
    return
  }
  const parent = document.getElementById('gridMainBox-left')
  if (!parent) {
    return
  }
  const body = document.body
  let vWinScrollTop = 0
  if (btn.getAttribute('data-selected') === 'true') {
    body.classList.remove('fixed')
    window.scrollTo(0, -parseInt(body.style.top))
  } else {
    if ('scrollingElement' in document && document.scrollingElement !== null) {
      vWinScrollTop = document.scrollingElement.scrollTop
    } else {
      vWinScrollTop = body.scrollTop
    }
    body.classList.add('fixed')
    body.style.top = -vWinScrollTop + 'px'
    // スマホで検索ウィンドウを開いたときにis-openedクラスを付加
    const spBox = document.querySelector('.md-search-box__inner')
    if (!spBox) {
      return
    }
    console.log(spBox)
    spBox.classList.add('is-opened')
    // スマホで検索ウィンドウを開いたときにis-hiddenクラスを付加
    const spBtn = document.querySelector('.is-SearchBoxNaviBtn')
    if (!spBtn) {
      return
    }
    spBtn.classList.add('is-hidden')
  }
  parent.setAttribute(
    'data-selected',
    parent.getAttribute('data-selected') === 'true' ? 'false' : 'true'
  )
}

/**
 * 検索ボックスを閉じる
 * @return {void}
 */
Vue.prototype.$$closeSearchBox = function (): void {
  const btn = document.getElementById('SearchBoxNaviBtn')
  if (!btn) {
    return
  }
  const parent = document.getElementById('gridMainBox-left')
  if (!parent) {
    return
  }
  const body = document.body
  body.classList.remove('fixed')
  window.scrollTo(0, -parseInt(body.style.top))
  parent.setAttribute(
    'data-selected',
    parent.getAttribute('data-selected') === 'true' ? 'false' : 'true'
  )
  // スマホで検索ウィンドウを閉じたときにis-openedクラスを除去
  const spBox = document.querySelector('.md-search-box__inner')
  if (!spBox) {
    return
  }
  spBox.classList.remove('is-opened')
  // スマホで検索ウィンドウを閉じたときにis-hiddenクラスを除去
  const spBtn = document.querySelector('.is-SearchBoxNaviBtn')
  if (!spBtn) {
    return
  }
  spBtn.classList.remove('is-hidden')
}

/**
 * ツリー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
const toggleTree = (e: HTMLElementEvent<HTMLElement>): void => {
  const id = e.target.getAttribute('data-key')
  if (!id) {
    return
  }
  const t = document.getElementById(id)
  if (!t) {
    return
  }
  const p = t.parentNode as HTMLElement
  if (!p) {
    return
  }
  t.setAttribute(
    'aria-expanded',
    t.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
  )
  p.setAttribute(
    'aria-expanded',
    p.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
  )
}

/**
 * クリックしてツリー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$toggleTreeByClick = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  toggleTree(e)
}

/**
 * キーダウンしてツリー表示を切り替える
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$toggleTreeByKeyDown = function (
  e: HTMLElementEvent<HTMLElement>
): void {
  // Space や Enter、Spacebar（IE11）が押されたかどうかを確認
  if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
    // デフォルトのアクションを防止して Space が押されたときにスクロールするのを止める
    e.preventDefault()
    toggleTree(e)
  }
}

/**
 * モーダルメニューの表示を切り替える
 * @return {void}
 */
Vue.prototype.$$toggleModalMenu = function (): void {
  const btn = document.getElementById('modal-header-menu-more')
  if (!btn) {
    return
  }
  const parent = document.getElementById('modal-header-menu')
  if (!parent) {
    return
  }
  btn.setAttribute(
    'aria-selected',
    btn.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
  parent.setAttribute(
    'aria-selected',
    parent.getAttribute('aria-selected') === 'true' ? 'false' : 'true'
  )
}

/**
 * Noを取得する
 * @params {string} kijiId
 * @return {number}
 */
Vue.prototype.$$getNo = function (kijiId: string): number {
  let no = 0
  for (const item of this.detailApi.itemInfo) {
    if (item[0] === kijiId) {
      no = item[1]
      break
    }
  }
  return no
}

/**
 * ゼロ埋め
 * @params {number} num
 * @params {number} length
 * @return {string}
 */
Vue.prototype.$$zeroPadding = function (num: number, length: number): string {
  if (String(num).length >= length) {
    return String(num)
  } else {
    return ('0000000000' + num).slice(-length)
  }
}

/**
 * 記事検索結果の見出しを取得する
 * @params {DangoResponse.searchKijiListsIF | DangoResponse.detailKijiListsIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getKijiTitle = function (
  list: DangoResponse.searchKijiListsIF | DangoResponse.detailKijiListsIF,
  flg: number = 1
): string {
  let title = ''

  // 全て可 or 切り抜きのみ不可 or 書誌・見出しのみ可
  if (
    this.$$getLoginInfo('ASA_SEARCH_FLAG') === 'Y' ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.ALL_OK ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.KIRINUKI_NG ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.SHOSHI_MIDASHI_OK
  ) {
    if (list.Midashi !== '') {
      // 改行コード変換、ハイライト表示
      title = list.Midashi.replace(/\n/g, '<br />')
      if (flg) {
        const keywords = this.detailApi.keywords
        if (keywords.length > 0) {
          const pattern =
            '(' +
            keywords.map((str: string) => regExpEscape(str)).join('|') +
            ')'
          const regexp = new RegExp(pattern, 'g')
          title = title.replace(regexp, '<span class="highlight">$1</span>')
        }
      }
    } else {
      title = '[見出しがありません]'
    }
    // 全て不可
  } else {
    title = '※著作権などの関係で見出しを表示できません。<BR>\n'
  }
  return title
}

/**
 * 記事検索結果の本文を取得する
 * @params {DangoResponse.detailKijiListsIF} list
 * @return {string}
 */
Vue.prototype.$$getKijiBody = function (
  list: DangoResponse.detailKijiListsIF
): string {
  let body = ''

  // 全て可 or 切り抜きのみ不可
  if (
    this.$$getLoginInfo('ASA_SEARCH_FLAG') === 'Y' ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.ALL_OK ||
    list.GaihanCtl === this.$$const.API_RESPONSE.GaihanCtl.KIRINUKI_NG
  ) {
    const keywords = this.detailApi.keywords
    const pattern =
      '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
    const regexp = new RegExp(pattern, 'g')

    // 記事本分
    if (list.Kiji !== '') {
      // 改行コード変換
      body = list.Kiji.replace(/\s+$/, '').replace(/\n/g, '<br />')
      // ハイライト表示
      if (this.detailApi.keywords.length > 0) {
        body = body.replace(regexp, '<span class="highlight">$1</span>')
      }
    }

    // 補助キーワード
    if (list.AssistKW !== '') {
      // 改行コード変換、ハイライト表示
      let assistKw = list.AssistKW.replace(/\s+$/, '').replace(/\n/g, '<br />')
      // ハイライト表示
      if (this.detailApi.keywords.length > 0) {
        assistKw = assistKw.replace(regexp, '<span class="highlight">$1</span>')
      }
      let assistKwLabel = '補助キーワード：'
      if (this.$i18n.locale === 'en') {
        assistKwLabel = 'Auxiliary keywords：'
      }
      body += '<br />' + assistKwLabel + assistKw + '<br />'
    }
    // 書誌・見出しのみ可 or 全て不可
  } else {
    body = '※著作権などの関係で本文を表示できません。<BR>\n'
  }
  return body
}

/**
 * 紙面KW検索結果の見出しを取得する
 * @params {DangoResponse.searchShimenListIF | DangoResponse.detailShimenMidashiDbdetailIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getShimenTitle = function (
  list:
    | DangoResponse.searchShimenListIF
    | DangoResponse.detailShimenMidashiDbdetailIF,
  flg: number = 1
): string {
  let title = ''

  if (!flg) {
    if (list.midashi !== '') {
      // 改行コード変換
      title = list.midashi.replace(/\n/g, '')
    } else {
      title = '[見出しがありません]'
    }
  } else if (list.midashi !== '') {
    // 改行コード変換
    title = list.midashi.replace(/\n/g, '<br />')

    const keywords = this.searchApi.keywords
    if (keywords.length > 0) {
      const pattern =
        '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
      const regexp = new RegExp(pattern, 'g')
      title = title.replace(regexp, '<span class="highlight">$1</span>')
    }
  } else {
    title = ''
  }

  return title
}

/**
 * 紙面KW検索結果のキーワードを取得する
 * @params {DangoResponse.searchShimenListIF|DangoResponse.detailShimenMidashiDbdetailIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getShimenKeyword = function (
  list:
    | DangoResponse.searchShimenListIF
    | DangoResponse.detailShimenMidashiDbdetailIF,
  flg: number = 1
): string {
  let res = ''

  // 一括更新時の特定文字削除
  let keyword = list.keyword
    .replace(/\$/g, '')
    .replace(/＄/g, '')
    .replace(/#/g, '')
    .replace(/＃/g, '')
  let bunruis = list.bunruis
    .replace(/\$/g, '')
    .replace(/＄/g, '')
    .replace(/#/g, '')
    .replace(/＃/g, '')

  if (!flg) {
    // 改行コード変換
    keyword = keyword.replace(/\n/g, '')
    bunruis = bunruis.replace(/\n/g, '')

    if (keyword !== '' && bunruis !== '') {
      res = keyword + '／' + bunruis
    } else if (keyword !== '' && bunruis === '') {
      res = keyword
    } else if (keyword === '' && bunruis !== '') {
      res = bunruis
    } else {
      res = '－'
    }
  } else {
    // 改行コード変換
    keyword = keyword.replace(/\n/g, '<br />')
    bunruis = bunruis.replace(/\n/g, '<br />')

    if (keyword !== '' && bunruis !== '') {
      res = keyword + '<br />' + bunruis
    } else if (keyword !== '' && bunruis === '') {
      res = keyword
    } else if (keyword === '' && bunruis !== '') {
      res = bunruis
    } else {
      res = ''
    }

    // ハイライト表示
    // フラグ有 かつ 検索対象が（M,K:見出しとキーワード、M,K,R:見出しとキーワードと分類）のいずれかの場合のみ
    const keywordTarget = this.searchApi.query.srchInfo[0].keywordTarget
    if (keywordTarget === 'M,K' || keywordTarget === 'M,K,R') {
      const keywords = this.searchApi.keywords
      if (keywords.length > 0) {
        const pattern =
          '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
        const regexp = new RegExp(pattern, 'g')
        res = res.replace(regexp, '<span class="highlight">$1</span>')
      }
    }
  }

  return res
}

/**
 * 紙面KW検索結果の分類を取得する
 * @params {DangoResponse.detailShimenMidashiDbdetailIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getShimeBunrui = function (
  list: DangoResponse.detailShimenMidashiDbdetailIF,
  flg: number = 1
): string {
  let res = ''

  // 大分類・中分類選択（0: 大分類, 1: 中分類）
  if (!flg) {
    res = list.bunruil
  } else {
    res = list.bunruim
  }

  // 改行コード変換
  res = res.replace(/\n/g, '<br />')

  // 一括更新時の特定文字削除
  res = res
    .replace(/\$/g, '')
    .replace(/＄/g, '')
    .replace(/#/g, '')
    .replace(/＃/g, '')

  // ハイライト表示
  // 検索対象が（M,K,R:見出しとキーワードと分類）の場合のみ
  const keywordTarget = this.searchApi.query.srchInfo[0].keywordTarget
  if (keywordTarget === 'M,K,R') {
    const keywords = this.searchApi.keywords
    if (keywords.length > 0) {
      const pattern =
        '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
      const regexp = new RegExp(pattern, 'g')
      res = res.replace(regexp, '<span class="highlight">$1</span>')
    }
  }
  return res
}

/**
 * 知恵蔵検索結果の見出しを取得する
 * @params {DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF} list
 * @params {number} flg
 * @return {string}
 */
Vue.prototype.$$getChiezoTitle = function (
  list: DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF,
  flg: number = 1
): string {
  let title = ''

  if (list.C_Midashi !== '') {
    title = list.C_Midashi
    if (list.C_MidashiAlph !== '') {
      title += '（' + list.C_MidashiAlph + '）'
    }

    // ハイライト
    if (flg) {
      const keywords = this.detailApi.keywords
      if (keywords.length > 0) {
        const pattern =
          '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
        const regexp = new RegExp(pattern, 'g')
        title = title.replace(regexp, '<span class="highlight">$1</span>')
      }
    }
  } else {
    title = '[見出しがありません]'
  }
  return title
}

/**
 * 知恵蔵検索結果の本文を取得する
 * @params {DangoResponse.detailKijiListsIF} list
 * @return {string}
 */
Vue.prototype.$$getChiezoBody = function (
  list: DangoResponse.detailChiezoListsIF
): string {
  let body = ''

  // 記事本分
  if (list.C_Kaisetsu !== '') {
    // 改行コード変換
    body = list.C_Kaisetsu.replace(/\s+$/, '').replace(/\n/g, '<br />')
    // ハイライト表示
    const keywords = this.detailApi.keywords
    if (keywords.length > 0) {
      const pattern =
        '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
      const regexp = new RegExp(pattern, 'g')
      body = body.replace(regexp, '<span class="highlight">$1</span>')
    }
  }
  return body
}

/**
 * 知恵蔵検索結果の分野を取得する
 * @params {DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF} list
 * @return {string}
 */
Vue.prototype.$$getChiezoField = function (
  list: DangoResponse.searchChiezoListsIF | DangoResponse.detailChiezoListsIF
): string {
  let field = ''

  if (list.C_BunyaL) {
    field = list.C_BunyaL
    if (list.C_BunyaS) {
      field += '-' + list.C_BunyaS
    }
  }
  return field
}

/**
 * 英文ニュース検索結果の見出しを取得する
 * @params {DangoResponse.searchEnglishListsIF} list
 * @return {string}
 */
Vue.prototype.$$getEnglishTitle = function (
  list: DangoResponse.searchEnglishListsIF
): string {
  let title = ''

  if (list.headline !== '') {
    title = list.headline.replace('\n', '<br />')
  } else {
    title = '[no english title]'
  }
  return title
}

/**
 * 英文ニュース検索結果の見出しを取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @return {string}
 */
Vue.prototype.$$getEnglishTitle2 = function (
  list: DangoResponse.detailEnglishListsIF
): string {
  let title = ''

  if (list.headline !== '') {
    title = list.headline.replace('\n', '<br />')
    title = title.replace(
      /<mark>(.*?)<\/mark>/g,
      '<span class="highlight">$1</span>'
    )
  } else {
    title = '[no english title]'
  }

  // 署名
  if (list.byline !== undefined && list.byline !== '') {
    title +=
      '<div class="md-article-card-title__subtitle">' + list.byline + '</div>'
  }

  return title
}

/**
 * 英文ニュース検索結果の本文へのページ内リンクを取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @return {{key:string, name:string}[]}
 */
Vue.prototype.$$getEnglishLink = function (
  list: DangoResponse.detailEnglishListsIF
): { key: string; name: string }[] {
  const links = []
  if (list.headline !== '' || list.body !== '') {
    links.push({ key: 'en', name: 'English' })
  }
  if (list.headline_jp !== '' || list.body_jp !== '') {
    links.push({ key: 'jp', name: 'Japanese' })
  }
  if (list.headline_tw !== '' || list.body_tw !== '') {
    links.push({ key: 'tw', name: 'Chinese(traditional)' })
  }
  if (list.headline_cn !== '' || list.body_cn !== '') {
    links.push({ key: 'cn', name: 'Chinese(simplified)' })
  }
  if (list.headline_ko !== '' || list.body_ko !== '') {
    links.push({ key: 'ko', name: 'Korean' })
  }
  if (links.length > 0 && (links.length !== 1 || links[0].key !== 'en')) {
    return links
  } else {
    return []
  }
}

/**
 * 英文ニュース検索結果の本文を取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @params {string} bodyName
 * @return {string}
 */
Vue.prototype.$$getEnglishBody = function (
  list: DangoResponse.detailEnglishListsIF,
  bodyName: string = 'body'
): string {
  let body = ''

  if (
    bodyName === 'body' ||
    bodyName === 'body_jp' ||
    bodyName === 'body_tw' ||
    bodyName === 'body_cn' ||
    bodyName === 'body_ko'
  ) {
    const str = list[bodyName]
    if (str !== undefined && str !== '') {
      body = str
    }
    if (bodyName === 'body') {
      if (body !== '') {
        body = body.replace(
          /<mark>(.*?)<\/mark>/g,
          '<span class="highlight">$1</span>'
        )
      } else {
        body = '[no english article]'
      }
    }
  }
  return body
}

/**
 * 英文ニュース検索結果の画像情報を取得する
 * @params {DangoResponse.detailEnglishListsIF} list
 * @return {DangoResponse.detailEnglishMedia[]}
 */
Vue.prototype.$$getEnglishMedia = function (
  list: DangoResponse.detailEnglishListsIF
): DangoResponse.detailEnglishMedia[] {
  const media: DangoResponse.detailEnglishMedia[] = []
  if (list.media !== undefined && list.media.length > 0) {
    for (const i in list.media) {
      if (
        list.media[i].media_value === 'photo-middle' &&
        list.fdata !== undefined &&
        list.fdata[i] !== undefined
      ) {
        list.media[i].fdata = list.fdata[i]
        media.push(list.media[i])
      }
    }
  }
  return media
}

/**
 * 人物DB検索結果を取得する
 * @params {DangoResponse.detailJimbutsuListsIF} list
 * @return {[string, string][]}
 */
Vue.prototype.$$getJimbutsuInfo = function (
  list: DangoResponse.detailJimbutsuListsIF
): [string, string][] {
  const res: [string, string][] = []

  // 本名
  if (list.honmyou !== undefined && list.honmyou !== '') {
    let val = list.honmyou
    if (list.honmyouyomi !== undefined && list.honmyouyomi !== '') {
      val += ' (' + list.honmyouyomi + ')'
    }
    res.push(['honmyou', val])
  }
  // 芸名・筆名
  if (list.hitsumei !== undefined && list.hitsumei !== '') {
    let val = list.hitsumei
    if (list.hitsumeiyomi !== undefined && list.hitsumeiyomi !== '') {
      val += ' (' + list.hitsumeiyomi + ')'
    }
    res.push(['hitsumei', val])
  }
  // 現職
  if (list.genshoku !== undefined && list.genshoku !== '') {
    res.push(['genshoku', list.genshoku])
  }
  // 所属会派
  if (list.kaiha !== undefined && list.kaiha !== '') {
    res.push(['kaiha', list.kaiha])
  }
  // 政党
  if (list.seitou !== undefined && list.seitou !== '') {
    res.push(['seitou', list.seitou])
  }
  // 選挙区
  if (list.senkyoku !== undefined && list.senkyoku !== '') {
    res.push(['senkyoku', list.senkyoku])
  }
  // 当選回数
  if (list.tousenkai !== undefined && String(list.tousenkai).length > 0) {
    res.push(['tousenkai', String(list.tousenkai)])
  }
  // 所属
  if (list.shozoku !== undefined && list.shozoku !== '') {
    res.push(['shozoku', list.shozoku])
  }
  // 前・元職
  if (list.motoshoku !== undefined && list.motoshoku !== '') {
    res.push(['motoshoku', list.motoshoku])
  }
  // 自宅住所
  if (list.jitakuad !== undefined && list.jitakuad !== '') {
    let val = ''
    if (list.jitakuzip !== undefined && list.jitakuzip !== '') {
      val = '〒' + list.jitakuzip + ' '
    }
    val += list.jitakuad + ' ' + list.jitakumeishou
    res.push(['jitakuad', val])
  }
  // 自宅電話
  if (list.jitakutel !== undefined && list.jitakutel !== '') {
    res.push(['jitakutel', list.jitakutel])
  }
  // 自宅ＦＡＸ
  if (list.jitakufax !== undefined && list.jitakufax !== '') {
    res.push(['jitakufax', list.jitakufax])
  }
  // 連絡先名称
  if (list.renrakumeishou !== undefined && list.renrakumeishou !== '') {
    res.push(['renrakumeishou', list.renrakumeishou])
  }
  // 連絡先住所
  if (list.renrakuad !== undefined && list.renrakuad !== '') {
    let val = ''
    if (list.renrakuzip !== undefined && list.renrakuzip !== '') {
      val = '〒' + list.renrakuzip + ' '
    }
    val += list.renrakuad
    res.push(['renrakuad', val])
  }
  // 連絡先電話
  if (list.renrakutel !== undefined && list.renrakutel !== '') {
    let val = list.renrakutel
    if (list.renrakuext !== undefined && list.renrakuext !== '') {
      val += ' 内線 ' + list.renrakuext
    }
    res.push(['renrakutel', val])
  }
  // 連絡先ＦＡＸ
  if (list.renrakufax !== undefined && list.renrakufax !== '') {
    res.push(['renrakufax', list.renrakufax])
  }
  // ＨＰ・Ｅ－ＭＡＩＬ
  if (list.internet !== undefined && list.internet !== '') {
    res.push(['internet', list.internet])
  }
  // 性別
  if (list.sex !== undefined && list.sex !== '') {
    res.push(['sex', list.sex + '性'])
  }
  // 生年月日
  if (list.birthday !== undefined && list.birthday !== '') {
    let val = ''
    const item = list.birthday.split('.')
    if (
      item[0] !== undefined &&
      item[1] !== undefined &&
      item[2] !== undefined
    ) {
      val = item[0] + '年 ' + item[1] + '月 ' + item[2] + '日'
    }
    if (list.fuki1 !== undefined && list.fuki1 !== '') {
      const item = list.fuki1.split('.')
      if (
        item[0] !== undefined &&
        item[1] !== undefined &&
        item[2] !== undefined
      ) {
        val += '(' + item[0] + '年 ' + item[1] + '月 ' + item[2] + '日 死亡)'
      }
    }
    res.push(['birthday', val])
  }
  // 出生地
  if (list.token2 !== undefined && list.token2 !== '') {
    let val = list.token2
    if (list.shigun2 !== undefined && list.shigun2 !== '') {
      val += ' ' + list.shigun2
    }
    res.push(['token2', val])
  }
  // 出身地
  if (list.token !== undefined && list.token !== '') {
    let val = list.token
    if (list.shigun !== undefined && list.shigun !== '') {
      val += ' ' + list.shigun
    }
    res.push(['token', val])
  }
  // 学歴
  if (list.gakureki !== undefined && list.gakureki !== '') {
    res.push(['gakureki', list.gakureki])
  }
  // 経歴
  if (list.keireki !== undefined && list.keireki !== '') {
    res.push(['keireki', list.keireki])
  }
  // 業績
  if (list.gyouseki !== undefined && list.gyouseki !== '') {
    res.push(['gyouseki', list.gyouseki])
  }
  // 学位・資格
  if (list.gakui !== undefined && list.gakui !== '') {
    res.push(['gakui', list.gakui])
  }
  // 入選・受賞
  if (list.jushou !== undefined && list.jushou !== '') {
    res.push(['jushou', list.jushou])
  }
  // 趣味・特技
  if (list.shumi !== undefined && list.shumi !== '') {
    res.push(['shumi', list.shumi])
  }
  // 更新日
  if (list.koushinbi !== undefined && list.koushinbi !== '') {
    let val = ''
    const item = list.koushinbi.split('.')
    if (
      item[0] !== undefined &&
      item[1] !== undefined &&
      item[2] !== undefined
    ) {
      val = item[0] + '年 ' + item[1] + '月 ' + item[2] + '日'
    }
    res.push(['koushinbi', val])
  }
  return res
}

/**
 * アサヒグラフの目次・概要を取得する
 * @params {string} summary
 * @params {number} highlightFlg
 * @params {number} shrinkFlg
 * @return {string}
 */
Vue.prototype.$$getGraphSummary = function (
  summary: string,
  highlightFlg: number = 0,
  shrinkFlg: number = 0
): string {
  let str: string = ''

  if (summary !== '') {
    // 改行コード変換
    summary = summary.replace(/\n/g, '')

    // 縮小処理
    if (shrinkFlg) {
      str = summary.substr(0, 115) + '…'
    } else {
      str = summary
    }

    // ハイライト表示
    if (highlightFlg) {
      const keywords = this.searchApi.keywords
      if (keywords.length > 0) {
        const pattern =
          '(' + keywords.map((str: string) => regExpEscape(str)).join('|') + ')'
        const regexp = new RegExp(pattern, 'g')
        str = str.replace(regexp, '<span class="highlight">$1</span>')
      }
      str = '目次・概要：' + str
    }
  } else {
    str = '[目次・概要がありません]'
  }
  return str
}

/**
 * アサヒグラフの発行誌種別を取得する
 * @params {string} code
 * @return {string}
 */
Vue.prototype.$$getGraphKind = function (code: string): string {
  let str: string = ''

  switch (code) {
    case 'A': // --- 通常号
      str = '通常号'
      break

    case 'B': // --- 臨時
      str = '臨時号'
      break

    case 'C': // --- 夏季臨時
      str = '夏季臨時号'
      break

    case 'D': // --- 臨時２
      str = '臨時号'
      break

    case 'E': // --- 英文
      str = '英文'
      break

    case 'F': // --- 臨時３
      str = '臨時号'
      break

    case 'G': // --- 臨時４
      str = '臨時号'
      break

    case 'H': // --- 付録１
      str = '付録'
      break

    case 'I': // --- 付録２
      str = '付録'
      break

    case 'J': // --- 別冊１
      str = '別冊'
      break

    case 'K': // --- 別冊２
      str = '別冊'
      break

    default:
      break
  }
  return str
}

/**
 * アサヒグラフの通巻を取得する
 * @params {string} code
 * @params {string} volume
 * @return {string}
 */
Vue.prototype.$$getGraphVolume = function (
  code: string,
  volume: string
): string {
  let str: string = ''

  if (code === 'A' && volume !== '') {
    const no: number = Number(volume)
    if (no >= 0 && no <= 9998) {
      str = str + no + '号'
    }
  }
  return str
}

/**
 * PDF画面の概要を開く
 * @return {void}
 */
Vue.prototype.$$openPdfSummary = function (): void {
  const openBtns = document.querySelector('.md-mokuji-gaiyo__readmore--open')
  if (!openBtns) {
    return
  }
  const closeBtns = document.querySelector('.md-mokuji-gaiyo__readmore--close')
  if (!closeBtns) {
    return
  }
  const textAreas = document.querySelector('.md-mokuji-gaiyo__text')
  if (!textAreas) {
    return
  }

  // 「目次・概要」を展開
  textAreas.classList.remove('is-minimized')
  textAreas.classList.add('is-maximized')
  // ボタン表示切り替え
  closeBtns.classList.remove('is-hidden')
  openBtns.classList.remove('is-visible')
  closeBtns.classList.add('is-visible')
  openBtns.classList.add('is-hidden')
}

/**
 * PDF画面の概要を開く
 * @return {void}
 */
Vue.prototype.$closePdfSummary = function (): void {
  const openBtns = document.querySelector('.md-mokuji-gaiyo__readmore--open')
  if (!openBtns) {
    return
  }
  const closeBtns = document.querySelector('.md-mokuji-gaiyo__readmore--close')
  if (!closeBtns) {
    return
  }
  const textAreas = document.querySelector('.md-mokuji-gaiyo__text')
  if (!textAreas) {
    return
  }

  // 「目次・概要」を閉じる
  textAreas.classList.remove('is-maximized')
  textAreas.classList.add('is-minimized')
  // ボタン表示切り替え
  openBtns.classList.remove('is-hidden')
  closeBtns.classList.remove('is-visible')
  openBtns.classList.add('is-visible')
  closeBtns.classList.add('is-hidden')
}

/**
 * eventを無効にする
 * @params {any} e
 * @return {void}
 */
const disableEvent = (e: any): void => {
  e.preventDefault()
}

/**
 * 画像上のtouchmoveを禁止する
 * @return {void}
 */
Vue.prototype.$$noTouchMoveOnImage = function (): void {
  const viewport = document.getElementById('dispCrop')
  if (!viewport) {
    return
  }
  viewport.addEventListener('touchmove', disableEvent, { passive: false })
}

/**
 * 画像を拡大縮小する
 * @params {HTMLElementEvent<HTMLElement>} e
 * @return {void}
 */
Vue.prototype.$$zoomImage = function (e: HTMLElementEvent<HTMLElement>): void {
  const id = e.target.id
  const img = document.getElementById('dispCrop_img') as HTMLImageElement
  if (img == null) {
    return
  }
  if (id === 'btnEnlargement') {
    img.width += imageZoomSize
  } else if (id === 'btnReduction') {
    if (img.width > imageZoomSize + 50) {
      img.width -= imageZoomSize
    }
  }
}

/**
 * スマホ・タブレットの場合はCSS「:hover」を全て削除する
 * @return {void}
 */
Vue.prototype.$$removeCssHover = function (): void {
  const touch =
    'ontouchstart' in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0

  if (touch) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      for (const si in document.styleSheets) {
        const styleSheet = document.styleSheets[si] as CSSStyleSheet
        if (!styleSheet.rules) continue

        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          const rule = styleSheet.rules[ri] as CSSStyleRule
          if (!rule.selectorText) continue

          if (rule.selectorText.match(':hover')) {
            styleSheet.deleteRule(ri)
          }
        }
      }
    } catch (ex) {}
  }
}

/**
 * UNIX時間を返す
 * @return {number}
 */
Vue.prototype.$$getUnixTime = function (): number {
  const date = new Date()
  const microsecound = date.getTime()
  return Math.floor(microsecound / 1000)
}

/**
 * ローカルストレージからデータを取得する
 * @params {string} key
 * @return {string}
 */
Vue.prototype.$$getDataFromLocalStorage = function (key: string): string {
  // ローカルストレージからデータを取得する
  let item = window.localStorage.getItem(key)

  if (key === '/kiji/') {
    if (item === null) {
      throw new NotFoundError()
    }
    // セッションストレージのデータを削除する
    window.localStorage.removeItem(key)
    return String(item)
  }

  // ローカルストレージにデータがあるか
  if (item === null) {
    // セッションストレージからデータを取得する
    item = window.sessionStorage.getItem(key)
    // console.log(item)

    // セッションストレージにデータがなければ404エラー
    if (item === null) {
      try {
        throw new NotFoundError()
      } catch (e) {
        this.$$redirectErrorPage(e)
      }
    }
  } else {
    // セッションストレージにデータを保存する
    window.sessionStorage.setItem(key, item)

    // ローカルストレージのデータを削除する
    window.localStorage.removeItem(key)
  }
  return String(item)
}

/**
 * 別ウインドウを開く
 * @params {string} path
 * @params {number} index
 * @params {string} target
 * @return {void}
 */
Vue.prototype.$$openNewWindow = function (
  path: string,
  index: number,
  target: string = '_blank'
): void {
  let val = ''
  if (path === '/kiji/') {
    val = JSON.stringify([this.srchInfo])
  } else if (
    path === '/kiji/print_list/' ||
    path === '/chiezo/print_list/' ||
    path === '/english/print_list/' ||
    path === '/jimbutsu/print_list/'
  ) {
    val = JSON.stringify([this.searchApi, this.kijiIdBox])
  } else if (
    path === '/kiji/detail/' ||
    path === '/kiji/print_detail/' ||
    path === '/chiezo/detail/' ||
    path === '/chiezo/print_detail/' ||
    path === '/jimbutsu/detail/' ||
    path === '/jimbutsu/print_detail/'
  ) {
    val = JSON.stringify(this.detailApi)
  } else if (path === '/english/detail/' || path === '/english/print_detail/') {
    if (index === 9) {
      const clone = Object.assign({}, this.detailApi)
      clone.srchOpt = this.$$const.API.srchOpt.NO_HISTORY
      clone.result = []
      val = JSON.stringify(clone)
    } else {
      val = JSON.stringify(this.detailApi)
    }
  } else if (path === '/kiji/image/' || path === '/kiji/memo/') {
    val = JSON.stringify(this.imageInfo)
  } else if (path === '/shimen/image2/') {
    val = JSON.stringify([this.searchApi, index, this.imageId])
  } else if (
    path === '/shimen/date_print_list/' ||
    path === '/shimen/print_region2/'
  ) {
    val = JSON.stringify(this.searchApi)
  } else if (path === '/shimen/kw_print_list/') {
    val = JSON.stringify([this.searchApi, this.dspKeyword])
  } else if (
    path === '/shimen/detail/' ||
    path === '/shimen/thumbnail/' ||
    path === '/shimen/detail2/' ||
    path === '/shimen/pdf2/'
  ) {
    val = JSON.stringify([this.searchApi, index])
  } else if (path === '/shimen/pdf/') {
    val = JSON.stringify([this.searchApi, index, this.pageIndex])
  } else if (
    path === '/shimen/crop_image/' ||
    path === '/shimen/crop_result/'
  ) {
    val = JSON.stringify([this.realDataApi, this.selectedSearch])
  } else if (path === '/shimen/print_pdf/') {
    val = JSON.stringify([
      this.realDataApi,
      this.selectedSearch,
      this.selectedDetail,
    ])
  } else if (path === '/shimen/print_pdf2/') {
    val = JSON.stringify([
      this.selectedSearch,
      this.detailApi,
      this.realDataApi,
    ])
  } else if (path === '/graph/print_list/') {
    val = JSON.stringify([this.searchApi, this.dspTitle])
  } else if (path === '/graph/thumbnail/') {
    val = JSON.stringify([this.searchApi, index])
  } else if (path === '/graph/pdf/') {
    val = JSON.stringify([this.searchApi, index, this.pageIndex])
  } else if (path === '/graph/print_pdf/') {
    val = JSON.stringify([
      this.searchApi,
      this.selectedSearch,
      this.selectedDetail,
      this.realDataApi,
    ])
  }
  if (val !== '') {
    window.localStorage.setItem(path, val)
  }
  window.open(path + '?' + new Date().getTime(), target)
}

/**
 * データをセット
 * @return {void}
 */
Vue.prototype.$$setData = function (): void {
  const path = this.$route.path
  const item = this.$$getDataFromLocalStorage(path)
  if (path === '/kiji/') {
    const arr = JSON.parse(item)
    this.srchInfo = arr[0]
  } else if (
    path === '/kiji/print_list/' ||
    path === '/chiezo/print_list/' ||
    path === '/english/print_list/' ||
    path === '/jimbutsu/print_list/'
  ) {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.kijiIdBox = arr[1]
  } else if (
    path === '/kiji/detail/' ||
    path === '/kiji/print_detail/' ||
    path === '/chiezo/detail/' ||
    path === '/chiezo/print_detail/' ||
    path === '/english/detail/' ||
    path === '/english/print_detail/' ||
    path === '/jimbutsu/detail/' ||
    path === '/jimbutsu/print_detail/'
  ) {
    this.detailApi = JSON.parse(item)
  } else if (path === '/kiji/image/' || path === '/kiji/memo/') {
    this.imageInfo = JSON.parse(item)
  } else if (path === '/shimen/image2/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.searchIndex = arr[1]
    this.imageId = arr[2]
  } else if (
    path === '/shimen/date_print_list/' ||
    path === '/shimen/print_region2/'
  ) {
    this.searchApi = JSON.parse(item)
  } else if (path === '/shimen/kw_print_list/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.dspKeyword = arr[1]
  } else if (
    path === '/shimen/detail/' ||
    path === '/shimen/thumbnail/' ||
    path === '/shimen/detail2/' ||
    path === '/shimen/pdf2/'
  ) {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.searchIndex = arr[1]
  } else if (path === '/shimen/pdf/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.searchIndex = arr[1]
    this.pageIndex = arr[2]
  } else if (
    path === '/shimen/crop_image/' ||
    path === '/shimen/crop_result/'
  ) {
    const arr = JSON.parse(item)
    this.realDataApi = arr[0]
    this.selectedSearch = arr[1]
  } else if (path === '/shimen/print_pdf/') {
    const arr = JSON.parse(item)
    this.realDataApi = arr[0]
    this.selectedSearch = arr[1]
    this.selectedDetail = arr[2]
  } else if (path === '/shimen/print_pdf2/') {
    const arr = JSON.parse(item)
    this.selectedSearch = arr[0]
    this.detailApi = arr[1]
    this.realDataApi = arr[2]
  } else if (path === '/graph/print_list/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.dspTitle = arr[1]
  } else if (path === '/graph/thumbnail/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.searchIndex = arr[1]
  } else if (path === '/graph/pdf/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.searchIndex = arr[1]
    this.pageIndex = arr[2]
  } else if (path === '/graph/print_pdf/') {
    const arr = JSON.parse(item)
    this.searchApi = arr[0]
    this.selectedSearch = arr[1]
    this.selectedDetail = arr[2]
    this.realDataApi = arr[3]
  }
}

/**
 * 刊種別コードから名称を取得するためのキーを返却
 * @params {string} kankind
 * @return {string}
 */
Vue.prototype.$$getKanshuNameParam = function (kankind: string): string {
  return Object.keys(this.$$const.API.kanshuName).filter(
    (key) => this.$$const.API.kanshuName[key] === kankind
  )[0]
}

/**
 * 発行社コードから名称を取得するためのキーを返却
 * @params {string} shacode
 * @return {string}
 */
Vue.prototype.$$gethakkoushaNameParam = function (shacode: string): string {
  return Object.keys(this.$$const.API.hakkoushaName).filter(
    (key) => this.$$const.API.hakkoushaName[key] === shacode
  )[0]
}

/**
 * 許可されている検索年代の権限一覧を取得
 * @return {string[]}
 */
Vue.prototype.$$getSrchAgesAuth = function (): string[] {
  const res: string[] = []

  Object.keys(this.$$const.API.srchAge).forEach((key: string) => {
    if (this.$$checkSrchAgesAuth(key)) {
      res.push(this.$$const.API.srchAge[key])
    }
  })
  return res
}

/**
 * 指定した検索年代の権限有無を取得
 * @params {string} target
 * @return {boolean}
 */
Vue.prototype.$$checkSrchAgesAuth = function (target: string): boolean {
  if (checkLoginData(target) === true && checkSvData(target) === true) {
    return true
  }
  return false
}

/**
 * 静的ページを開く
 * @params {string} key
 * @params {string} locale
 * @return {void}
 */
Vue.prototype.$$openStaticPage = function (
  key: string,
  locale: string = ''
): void {
  const svSname = String(this.$$getLoginInfo('SV_SNAME'))
  let list = this.$$const.STATIC_PAGE[key]
  if (list !== undefined) {
    const path = list[svSname]
    if (path !== undefined) {
      window.open(path, '_blank')
      return
    }
  }
  let localeKey = this.$i18n.locale
  if (locale !== '') {
    localeKey = locale
  }
  list = this.$$const.STATIC_PAGE[localeKey]
  if (list !== undefined) {
    const path = list[key]
    if (path !== undefined) {
      window.open(path, '_blank')
    }
  }
}

/**
 * 選択文字列をセットする
 * @return {boolean}
 */
Vue.prototype.$$setSelection = function (): boolean {
  // 変数初期化
  this.selecionId1 = ''
  this.selecionId2 = ''
  this.selecionId = ''
  this.selectionStr = ''
  this.selectionKeyword = ''

  // 選択文字列が空かチェックする
  const selection = window.getSelection()
  if (!selection) {
    return false
  }
  if (selection.toString() === '') {
    return false
  }

  // 複数記事の文字列を選択していないかチェックする
  const anchorNode = selection.anchorNode // selectionの開始ノード
  const focusNode = selection.focusNode //  selectionの終了ノード
  console.log(selection)
  if (!anchorNode || !focusNode) {
    return false
  }
  if (!anchorNode.parentElement || !focusNode.parentElement) {
    return false
  }
  const el1 = anchorNode.parentElement.closest('.md-article-card')
  const el2 = focusNode.parentElement.closest('.md-article-card')
  console.log(el1, el2)
  if (!el1 || !el2) {
    return false
  }
  this.selectionId1 = el1.getAttribute('id')
  this.selectionId2 = el2.getAttribute('id')
  if (this.selectionId1 !== this.selectionId2) {
    return false
  }

  // 選択文字列をセット
  this.selectionId = this.selectionId1
  this.selectionStr = selection.toString()
  this.selectionKeyword = this.selectionStr.replace(/\n/g, '')
  console.log(this.selectionId, this.selectionStr)

  return true
}

/**
 * ツールボックスを開く
 * @return {void}
 */
Vue.prototype.$$closeToolBox = function (): void {
  console.log('closeToolBox')
  this.selecionId = ''
  this.selectionStr = ''
  this.selectionKeyword = ''
  const toolbox = document.getElementById('toolbox')
  if (!toolbox) {
    return
  }
  toolbox.style.display = 'none'
  window.getSelection()!.removeAllRanges()
}

/**
 * ツールボックスを開く
 * @params {any} e
 * @return {Promise<void>}
 */
Vue.prototype.$$openToolBox = async function (e: any): Promise<void> {
  const targetId = e.target.getAttribute('id')
  console.log('openToolBox', e.type, targetId)
  if (targetId !== null && targetId.match(/^toolbox-/)) {
    return
  }

  const headers = document.getElementsByTagName('header')
  if (!headers) {
    return
  }
  const header = headers.item(0)
  if (!header) {
    return
  }
  const toolbox = document.getElementById('toolbox')
  if (!toolbox) {
    return
  }

  const el =
    this.$route.path === '/kiji/detail/'
      ? document.getElementById('gridMainBox-center')
      : document.getElementById('gridMainBox-right')
  if (!el) {
    return
  }

  // Androidなどでイベント発生時に選択文字列がセットされていない場合がある
  // 選択文字列が取得できるか調べるためのリトライ処理
  for (let i = 0; i < 5; ++i) {
    // 選択文字列をセットする
    const res = this.$$setSelection()
    if (!res) {
      // ツールボックスを閉じる
      this.$$closeToolBox()
    } else {
      // ツールボックスを表示する
      let y = 0
      if (e.pageY !== undefined) {
        y = e.pageY
      } else {
        y = e.changedTouches[0].pageY
      }
      const top = el.scrollTop + y - header.clientHeight + 40
      toolbox.style.top = top + 'px'
      if (this.$route.path === '/kiji/detail/') {
        const left = document.body.clientWidth / 2 - 200
        toolbox.style.left = left + 'px'
      }
      toolbox.style.display = ''
    }

    // スリープ
    const sleep = (msec: number) => {
      return new Promise((resolve) => setTimeout(resolve, msec))
    }
    await sleep(100)
  }
}

/**
 * 選択文字列検索ツールボックスを登録する
 * @return {void}
 */
Vue.prototype.$$registerToolBox = function (): void {
  const self = this
  const toolbox = document.getElementById('toolbox')
  if (!toolbox) {
    return
  }
  const appContainer =
    this.$route.path === '/kiji/detail/'
      ? document.getElementById('gridMainBox-center')
      : document.getElementById('gridMainBox-right')
  if (!appContainer) {
    return
  }

  // ボタンのclickイベントを登録する
  for (const str of ['top', 'kiji', 'shimen', 'chiezo', 'jimbutsu', 'graph']) {
    const el = document.getElementById('toolbox-' + str)
    if (el) {
      el.addEventListener('click', function () {
        self.$$searchBySelectionKeyword(str)
      })
      el.addEventListener('touchend', function () {
        self.$$searchBySelectionKeyword(str)
      })
    }
  }

  const toolboxQuote = document.getElementById('toolbox-quote')
  if (toolboxQuote) {
    toolboxQuote.addEventListener('click', function () {
      self.$$addQuote()
    })
    toolboxQuote.addEventListener('touchend', function () {
      self.$$addQuote()
    })
  }

  // マイフォルダアイコンクリック時はツールボックスを閉じる
  const myFolderBtn = document.getElementById('myFolderBtn')
  if (myFolderBtn) {
    myFolderBtn.addEventListener('click', function () {
      self.$$closeToolBox()
    })
  }

  // イベントを登録
  appContainer.addEventListener('selectstart', function () {
    self.$$closeToolBox()
  })
  appContainer.addEventListener('mouseup', function (e: any) {
    self.$$openToolBox(e)
  })
  toolbox.addEventListener('mousedown', function (e: any) {
    e.preventDefault()
  })
  if (isSpDevice()) {
    appContainer.addEventListener('touchstart', function (e: any) {
      self.$$openToolBox(e)
    })
    appContainer.addEventListener('touchend', function (e: any) {
      self.$$openToolBox(e)
    })
    toolbox.addEventListener('touchstart', function (e: any) {
      e.preventDefault()
    })
    toolbox.addEventListener('touchend', function (e: any) {
      e.preventDefault()
    })
  }
}

/**
 * 選択文字列で検索する
 * @params {string} name
 * @return {void}
 */
Vue.prototype.$$searchBySelectionKeyword = function (name: string): void {
  console.log('$$searchBySelectionKeyword')
  // 選択文字列をセットする
  const res = this.$$setSelection()
  if (!res) {
    if (this.selectionId1 !== this.selectionId2) {
      alert(this.$t('message.toolBox.overSelectionRange'))
    }
    // ツールボックスを閉じる
    this.$$closeToolBox()
    return
  }

  // メンテナンス中か確認する
  if (this.$$checkMaintenance('/' + name + '/')) {
    alert(this.$t('message.maintenance'))
    return
  }

  // 親コンポーネントにキーワードの値を渡す
  this.$nuxt.$emit('updateXKeyword', this.selectionKeyword)

  if (this.$route.name === name) {
    this.$$getMainVueInstance().reset()
    this.$$getMainVueInstance().srchInfo[0].keyword = this.selectionKeyword
    this.$$getMainVueInstance().search(0)

    // ツールボックスを閉じる
    this.$$closeToolBox()
  } else {
    this.$router.push({
      name,
      params: { xKeyword: this.selectionKeyword },
    })
  }
}

/**
 * 引用を追加する
 * @return {Promise<void>}
 */
Vue.prototype.$$addQuote = async function (): Promise<void> {
  console.log('$$addQuote')
  // 選択文字列をセットする
  const res = this.$$setSelection()
  if (!res) {
    if (this.selectionId1 !== this.selectionId2) {
      alert(this.$t('message.toolBox.overSelectionRange', 'ja'))
    }
    // ツールボックスを閉じる
    this.$$closeToolBox()
    return
  }

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'quoteNotOpen')
    return
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['QUOTE'])

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('QUOTE', 'detailCount') + 1 >
    this.$$const.MY_FOLDER.QUOTE_LIMIT
  ) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'quoteIsFull')
    return
  }

  // 詳細情報が存在するかチェック
  let list = {}
  for (const obj of this.$$getMainVueInstance().detailApi.result.lists) {
    if (obj.KijiId === this.selectionId) {
      list = obj
      break
    }
  }
  if (!list) {
    return
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.quoteDetailIF[] }
  const updatetime = dayjs().format('YYYYMMDDHHmmss')
  json.detail.push({
    uuid: '',
    updatetime,
    kijiId: this.selectionId,
    text: JSON.stringify(this.selectionStr),
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res2 = await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.QUOTE,
    hznJson
  )

  if (res2) {
    // 引用を整形する
    this.$$formatQuote(
      updatetime,
      this.selectionStr,
      list,
      this.$$getKijiTitle(list, 0)
    )

    // 引用をセット
    this.$nuxt.$emit('setQuote', this.quote)

    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'addQuote')
  }
}

/**
 * 引用を整形する
 * @params {string} updatetime
 * @params {string} text
 * @params {any} list
 * @params {string} title
 * @return {void}
 */
Vue.prototype.$$formatQuote = function (
  updatetime: string,
  text: string,
  list: any,
  title: string
): void {
  this.quote =
    text +
    '【' +
    list.ShishiName +
    this.$$getDate(list.HakkouDate + '000000', 'YYYY年MM月DD日') +
    list.KanshuName +
    list.Page +
    'ページ「' +
    title +
    '」（「' +
    this.$config.SERVICE_NAME +
    '」から' +
    this.$$getDate(updatetime + '000000', 'YYYY年MM月DD日') +
    '情報取得）】'
}

/**
 * 引用をコピーする
 * @return {void}
 */
Vue.prototype.$$copyQuote = function (): void {
  console.log('$$copyQuote')
  const self = this
  navigator.clipboard
    .writeText(this.quote)
    .then(() => {
      alert(self.$t('message.copySuccess', 'ja'))
    })
    .catch((e) => {
      alert(self.$t('message.copyFailed', 'ja'))
      console.log(e)
    })
}

/**
 * 検索条件をローカライズする
 * @params {any} srchInfo
 * @return {any}
 */
Vue.prototype.$$localizePresearch = function (srchInfo: any): any {
  const getItaijiFlg = (v: string) => {
    const obj = this.$$const.API.itaijiFlg
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getDougigoFlg = (v: string) => {
    const obj = this.$$const.API.dougigoFlg
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getShishiName = (v: string) => {
    const obj = this.$$const.API.shishiName
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getSrchTerm = (v: string) => {
    const obj = this.$$const.API.srchTerm
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getKeywordTarget = (v: string) => {
    const obj = this.$$const.API.keywordTarget
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getKanshuName = (v: string) => {
    const obj = this.$$const.API.kanshuName
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getMenshuAName = (v: string) => {
    const obj = this.$$const.API.menshuAName
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const getHakkoushaNames = (v: string) => {
    const obj = this.$$const.API.hakkoushaName
    return Object.keys(obj).find((k) => obj[k] === v)
  }
  const shishiNames = []
  for (const v of srchInfo.shishiNames) {
    shishiNames.push(this.$t('shishiNameParamLabel.' + getShishiName(v), 'ja'))
  }
  if (shishiNames.length === 0) {
    shishiNames.push(this.$t('unspecifiedLabel', 'ja'))
  }
  const itaijiFlg = this.$t(
    'itaijiFlgParamLabel.' + getItaijiFlg(srchInfo.itaijiFlg),
    'ja'
  )
  const dougigoFlg = this.$t(
    'dougigoFlgParamLabel.' + getDougigoFlg(srchInfo.dougigoFlg),
    'ja'
  )
  let hakkouDate = ''
  if (srchInfo.srchTerm !== this.$$const.API.srchTerm.FROM_TO) {
    hakkouDate += this.$t(
      'srchTermParamLabel.' + getSrchTerm(srchInfo.srchTerm),
      'ja'
    )
  } else {
    if (srchInfo.hakkouDateF !== '') {
      hakkouDate += this.$$getDate(
        srchInfo.hakkouDateF + '000000',
        'YYYY年MM月DD日'
      )
      hakkouDate += this.$t('fromLabel', 'ja')
    }
    if (srchInfo.hakkouDateT !== '') {
      hakkouDate += this.$$getDate(
        srchInfo.hakkouDateT + '000000',
        'YYYY年MM月DD日'
      )
      hakkouDate += this.$t('toLabel', 'ja')
    }
  }
  const keywordTarget = this.$t(
    'keywordTargetParamLabel.' + getKeywordTarget(srchInfo.keywordTarget),
    'ja'
  )
  let bunrui1 = srchInfo.bunrui1
  if (srchInfo.bunrui1 === '') {
    bunrui1 = this.$t('unspecifiedLabel', 'ja')
  }
  const kanshuNames = []
  for (const v of srchInfo.kanshuNames) {
    kanshuNames.push(this.$t('kanshuNameParamLabel.' + getKanshuName(v), 'ja'))
  }
  if (kanshuNames.length === 0) {
    kanshuNames.push(this.$t('unspecifiedLabel', 'ja'))
  }
  let menName = srchInfo.menName
  if (srchInfo.menName === '') {
    menName = this.$t('unspecifiedLabel', 'ja')
  }
  const menshuANames = []
  for (const v of srchInfo.menshuANames) {
    menshuANames.push(
      this.$t('menshuANameParamLabel.' + getMenshuAName(v), 'ja')
    )
  }
  if (menshuANames.length === 0) {
    menshuANames.push(this.$t('unspecifiedLabel', 'ja'))
  }
  const hakkoushaNames = []
  for (const v of srchInfo.hakkoushaNames) {
    hakkoushaNames.push(
      this.$t('hakkoushaNameParamLabel.' + getHakkoushaNames(v), 'ja')
    )
  }
  if (hakkoushaNames.length === 0) {
    hakkoushaNames.push(this.$t('unspecifiedLabel', 'ja'))
  }
  let kirinukiUseReq = this.$t('kirinukiUseReqParamLabel.FALSE', 'ja')
  if (srchInfo.kirinukiUseReq === this.$$const.API.kirinukiUseReq.TRUE) {
    kirinukiUseReq = this.$t('kirinukiUseReqParamLabel.TRUE', 'ja')
  }
  let dspOrder = this.$t('dspOrderParamLabel.OLDEST_TO_NEWEST', 'ja')
  if (srchInfo.dspOrder === this.$$const.API.dspOrder.NEWEST_TO_OLDEST) {
    dspOrder = this.$t('dspOrderParamLabel.NEWEST_TO_OLDEST', 'ja')
  } else if (srchInfo.dspOrder === this.$$const.API.dspOrder.RELEVANCE) {
    dspOrder = this.$t('dspOrderParamLabel.RELEVANCE', 'ja')
  }
  return {
    keyword: srchInfo.keyword,
    itaijiFlg,
    dougigoFlg,
    shishiNames,
    hakkouDate,
    keywordTarget,
    bunrui1,
    kanshuNames,
    menName,
    menshuANames,
    hakkoushaNames,
    kirinukiUseReq,
    dspNum: srchInfo.dspNum,
    dspOrder,
  }
}

/**
 * 検索条件を追加する
 * @return {Promise<void>}
 */
Vue.prototype.$$addPresearch = async function (): Promise<void> {
  console.log('$$addPresearch')

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'presearchNotOpen')
    return
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['PRESEARCH'])

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('PRESEARCH', 'detailCount') + 1 >
    this.$$const.MY_FOLDER.PRESEARCH_LIMIT
  ) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'presearchIsFull')
    return
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.presearchDetailIF[] }
  json.detail.push({
    uuid: '',
    updatetime: dayjs().format('YYYYMMDDHHmmss'),
    srchInfo: JSON.stringify(this.srchInfo),
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res2 = await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.PRESEARCH,
    hznJson
  )

  if (res2) {
    // 検索条件をローカライズする
    this.$nuxt.$emit(
      'setSrchCondition',
      this.$$localizePresearch(this.srchInfo[0])
    )

    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'addPresearch')
  }
}

/**
 * お気に入り記事を追加する
 * @paramas {string} kijiId
 * @return {Promise<void>}
 */
Vue.prototype.$$addFavoriteKiji = async function (
  kijiId: string = ''
): Promise<void> {
  console.log('$$addFavoriteKiji')
  // バリデーション
  if (kijiId === '' && this.kijiIdBox.length === 0) {
    alert('記事を選択してください。')
    return
  }

  // マイフォルダを開いているかチェック
  if (!this.$$getLoginInfo('S_HZNFOLDER')) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'favoriteKijiNotOpen')
    return
  }

  // マイフォルダJSONを取得する
  await this.$$getMyFolderJson(['FAVORITE_KIJI'])

  // 登録済みの記事IDを取り出す
  const registeredKijiIds = []
  for (const detail of this.$$getMyFolderInfo('FAVORITE_KIJI', 'detail')) {
    registeredKijiIds.push(detail.kijiId)
  }

  // 登録済みの記事を除外する
  const kijiIds = []
  if (kijiId !== '') {
    if (!registeredKijiIds.includes(kijiId)) {
      kijiIds.push(kijiId)
    }
  } else {
    for (const info of this.kijiIdBox) {
      if (!registeredKijiIds.includes(info[0])) {
        kijiIds.push(info[0])
      }
    }
  }
  console.log(kijiIds)
  if (kijiIds.length === 0) {
    alert('登録済みの記事です。')
    this.$$getMainVueInstance().$forceUpdate()
    return
  }

  // 登録上限数を超えないかチェック
  if (
    this.$$getMyFolderInfo('FAVORITE_KIJI', 'detailCount') + kijiIds.length >
    this.$$const.MY_FOLDER.FAVORITE_KIJI_LIMIT
  ) {
    // メッセージ表示
    this.$nuxt.$emit('setAlertId', 'favoriteKijiIsFull')
    return
  }

  // 保存情報を整形する
  const json = { detail: [] as MyFolder.favoriteKijiDetailIF[] }
  for (const kijiId of kijiIds) {
    console.log(kijiId)
    json.detail.push({
      uuid: '',
      updatetime: dayjs().format('YYYYMMDDHHmmss'),
      kijiId,
      memo: JSON.stringify(''),
    })
  }
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res2 = await this.$$addMyFolderInfo(
    this.$$const.API.kinouId.FAVORITE_KIJI,
    hznJson
  )

  if (res2) {
    if (kijiId !== '') {
      // マイフォルダJSONを取得する
      await this.$$getMyFolderJson(['FAVORITE_KIJI'])

      // 強制的に再レンダリング
      this.$forceUpdate()

      // uuidを調べる
      let uuid = ''
      for (const detail of this.$$getMyFolderInfo('FAVORITE_KIJI', 'detail')) {
        if (detail.kijiId === kijiId) {
          uuid = detail.uuid
          break
        }
      }

      // メッセージ用に記事詳細をセット
      for (const list of this.$$getMainVueInstance().detailApi.result.lists) {
        if (list.KijiId === kijiId) {
          list.uuid = uuid
          this.$nuxt.$emit('setFavoriteKijiInfo', list)
          break
        }
      }

      // メッセージ表示
      this.$nuxt.$emit('setAlertId', 'addFavoriteKiji')
    } else {
      // メッセージ表示
      this.$nuxt.$emit('setAlertId', 'addFavoriteKijis')
    }
  }
}

/**
 * お気に入り記事のメモを更新する
 * @params {string} uuid
 * @params {string} kijiId
 * @params {number} i
 * @return {Promise<void>}
 */
Vue.prototype.$$updateFavoriteKijiMemo = async function (
  uuid: string,
  kijiId: string,
  i: number = 0
): Promise<void> {
  console.log('$$updateFavoriteKijiMemo', uuid, kijiId)

  if (!kijiId) {
    return
  }

  // 保存情報を整形する
  const memo = this.memo[uuid] ? this.memo[uuid] : ''
  const json = { detail: [] as MyFolder.favoriteKijiDetailIF[] }
  json.detail.push({
    uuid,
    updatetime: dayjs().format('YYYYMMDDHHmmss'),
    kijiId,
    memo: JSON.stringify(memo),
  })
  const hznJson = JSON.stringify(json)
  console.log(hznJson)

  // マイフォルダ保存情報を更新する
  const res = await this.$$updateMyFolderInfo(
    this.$$const.API.kinouId.FAVORITE_KIJI,
    uuid,
    hznJson
  )

  if (res) {
    if (this.checkedMemos !== undefined) {
      this.detail.FAVORITE_KIJI[i].memo = JSON.stringify(memo)
      this.checkedMemos = []
    }
    if (this.closeFlg !== undefined) {
      this.closeFlg = 1
    }
  }
}

/**
 * 日付を取得する
 * @params {string} datetime
 * @params {string} format
 * @return {string}
 */
Vue.prototype.$$getDate = function (datetime: string, format: string): string {
  return dayjs(datetime, 'YYYYMMDDHHmmss').format(format)
}

/**
 * 初期値を取得する
 * @params {string} key
 * @return {any}
 */
Vue.prototype.$$getInitialValue = function (key: string): any {
  if (key === 'detail') {
    return {
      QUOTE: [],
      PRESEARCH: [],
      FAVORITE_KIJI: [],
    } as MyFolder.detailIF
  } else if (key === 'searchApi') {
    return {
      query: {
        srchInfo: [
          {
            dspNum: null,
            dspOrder: null,
          },
        ],
      },
      condition: [],
      result: [] as DangoResponse.searchResultIF[],
      totalCount: -1,
      page: 1,
      start: null,
      end: null,
    }
  } else if (key === 'detailApi') {
    return {
      query: {},
      result: {
        lists: [] as DangoResponse.searchKijiListsIF[],
      },
      result2: {} as any,
      itemInfo: [] as string[][],
      srchId: this.$$const.API.srchId.KIJI,
      thumbnailFlg: this.$$const.API.thumbnailFlg.FALSE,
      srchOpt: this.$$const.API.srchOpt.MYFOLDER_NO_HISTORY,
      dspOrder: this.$$const.API.dspOrder.NEWEST_TO_OLDEST,
      keywords: [],
    }
  } else if (key === 'page') {
    return {
      QUOTE: 1,
      PRESEARCH: 1,
      FAVORITE_KIJI: 1,
    }
  } else if (key === 'srchCondition') {
    return {
      keyword: '',
      itaijiFlg: '',
      dougigoFlg: '',
      shishiNames: [],
      hakkouDate: '',
      keywordTarget: '',
      bunrui1: '',
      kanshuNames: [],
      menName: '',
      menshuANames: [],
      hakkoushaNames: [],
      kirinukiUseReq: '',
      dspNum: '',
      dspOrder: '',
    }
  } else {
    return null
  }
}

/**
 * ロケールメッセージ文字列を取得する
 * @params {string} key
 * @return {string}
 */
Vue.prototype.$$getLocaleMessage = function (key: string): string {
  let locale = this.$i18n.locale
  const svSname = String(this.$$getLoginInfo('SV_SNAME'))
  if (key === 'message.downloadPdf.confirm') {
    if (svSname === '朝クロサーチ中国' || svSname === '朝クロサーチ海外') {
      locale = 'en'
    }
  }
  return String(this.$t(key, locale))
}

/**
 * ログインページのページパスをローカルストレージに保存する
 * @return {void}
 */
Vue.prototype.$$setLoginPagePath = function (): void {
  // ローカルストレージにページパスを保存する
  window.localStorage.setItem(
    this.$$const.STORAGE_KEY.LOGIN_PATH,
    this.$route.path
  )
}

/**
 * 文字を統一する
 * @params {string} str
 * @return {string}
 */
Vue.prototype.$$unifyChar = function (str: string): string {
  // for (let i = 0; i < str.length; i++) {
  //   console.log(str[i], str.charCodeAt(i).toString(16))
  // }

  // 全角チルダ"～"（\uFF5E）に統一
  str = str.replace(/\u007E/g, '\uFF5E') // チルダ"~"
  str = str.replace(/\u301C/g, '\uFF5E') // 波ダッシュ"〜"

  // 全角ハイフン"－"(\uFF0D) に統一
  str = str.replace(/\u002D/g, '\uFF0D') // ハイフンマイナス"-"
  str = str.replace(/\u2010/g, '\uFF0D') // ハイフン"‐"
  str = str.replace(/\u2012/g, '\uFF0D') // ダッシュ"‒"
  str = str.replace(/\u2013/g, '\uFF0D') // 半角ダッシュ"–"
  str = str.replace(/\u207B/g, '\uFF0D') // マイナス"⁻"
  str = str.replace(/\u208B/g, '\uFF0D') // 下付き文字マイナス"₋"
  str = str.replace(/\u2212/g, '\uFF0D') // 全角マイナス"−"
  str = str.replace(/\uFFE3/g, '\uFF0D') // 全角オーバーライン"￣"

  // 水平バー"―"（\u2015）に統一
  str = str.replace(/\u2014/g, '\u2015') // 全角ダッシュ"—"

  return str
}
